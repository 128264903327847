import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import {IconButton} from '@mui/material';

export const Notifications = () => {
    return (
        <IconButton>
            <NotificationsOutlinedIcon fontSize={'medium'} />
        </IconButton>
    );
};
