import {IMask} from 'react-imask';

export const drawerWidth = 260;

export enum ROUTES {
    BASE = '/',
    AUTH = '/login',
    PROFILE = '/profile',
    NEWS = '/news',
    REQUESTS = '/requests',
    KNOWLEDGE_BASE = '/knowledge-base',
    STORAGE = '/storage',
    AUDITS = '/audits',
    USERS = '/users',
    TEST = '/test',
    SCHEDULER = '/scheduler',
    SSO_AUTH = '/login/sso',
    TESTEST = '/test/test'
}

export enum ERROR_MESSAGE {
    REQUIRED = 'Обязательное поле',
    REQUIRED_EMPTY = ' ',
    EMAIL = 'Неверный формат e-mail',
    INVALID_CREDENTIALS = 'Неверная почта или пароль',
    USER_BLOCKED = 'Пользователь заблокирован. Обратитесь к администратору системы'
}

export const MASKS = {
    NAME: /[ЁёА-яa-zA-Z\s-]$/,
    PHONE: '+{7} (000) 000-00-00',
    EMAIL: /^\S*@?\S*$/,
    TIME: {
        mask: 'hh:mm',
        lazy: false,
        autofix: true,
        blocks: {
            hh: {mask: IMask.MaskedRange, placeholderChar: '0', from: 0, to: 23, maxLength: 1},
            mm: {mask: IMask.MaskedRange, placeholderChar: '0', from: 0, to: 59, maxLength: 1}
        }
    }
};
