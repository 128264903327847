import {ThemeOptions} from '@mui/material';

export const blackTheme: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#7367f0',
            200: '#f067692e',
            contrastText: '#2f2b3d'
        },
        secondary: {
            main: '#FF3F3F'
        },
        background: {
            default: '#25293C',
            paper: '#2F3349'
        },
        common: {border: '#d1d0d4', shadow: '#2f2b3d'},
        grey: {500: '#2f2b3d'},
        action: {
            hover: 'rgba(225, 222, 245, 0.06)',
            selected: '#3a3b64'
        },
        text: {primary: '#E1DEF5E6', white: '#FFFFFF', heading: '#cfcde4'},

        success: {
            main: '#28c76f'
        }
    }
};
