/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BANDA API
 * OpenAPI spec version: 1.0.0
 */
import type {MutationFunction, UseMutationOptions, UseMutationResult} from '@tanstack/react-query';
import {useMutation} from '@tanstack/react-query';
import type {
    PostApiSsoAuthCheckSession200,
    PostApiSsoAuthCheckSession401,
    PostApiSsoAuthCheckSession403,
    PostApiSsoAuthCheckSession422,
    PostApiSsoAuthCheckSession500,
    PostApiSsoAuthCheckSessionBody,
    PostApiSsoAuthLoginAuthRequest200,
    PostApiSsoAuthLoginAuthRequest401,
    PostApiSsoAuthLoginAuthRequest403,
    PostApiSsoAuthLoginAuthRequest422,
    PostApiSsoAuthLoginAuthRequest500,
    PostApiSsoAuthLoginAuthRequestBody,
    PostApiSsoAuthTokenAuthRequest200,
    PostApiSsoAuthTokenAuthRequest401,
    PostApiSsoAuthTokenAuthRequest403,
    PostApiSsoAuthTokenAuthRequest422,
    PostApiSsoAuthTokenAuthRequest500,
    PostApiSsoAuthTokenAuthRequestBody
} from './bandaApi.schemas';
import {axiosInstance} from './mutator/axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Авторизация через форму
 */
export const postApiSsoAuthLoginAuthRequest = (
    postApiSsoAuthLoginAuthRequestBody: PostApiSsoAuthLoginAuthRequestBody
) => {
    return axiosInstance<PostApiSsoAuthLoginAuthRequest200>({
        url: `/api/sso/auth/loginAuthRequest`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: postApiSsoAuthLoginAuthRequestBody
    });
};

export const getPostApiSsoAuthLoginAuthRequestMutationOptions = <
    TError =
        | PostApiSsoAuthLoginAuthRequest401
        | PostApiSsoAuthLoginAuthRequest403
        | PostApiSsoAuthLoginAuthRequest422
        | PostApiSsoAuthLoginAuthRequest500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiSsoAuthLoginAuthRequest>>,
        TError,
        {data: PostApiSsoAuthLoginAuthRequestBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiSsoAuthLoginAuthRequest>>,
    TError,
    {data: PostApiSsoAuthLoginAuthRequestBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiSsoAuthLoginAuthRequest>>,
        {data: PostApiSsoAuthLoginAuthRequestBody}
    > = (props) => {
        const {data} = props ?? {};

        return postApiSsoAuthLoginAuthRequest(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiSsoAuthLoginAuthRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiSsoAuthLoginAuthRequest>>
>;
export type PostApiSsoAuthLoginAuthRequestMutationBody = PostApiSsoAuthLoginAuthRequestBody;
export type PostApiSsoAuthLoginAuthRequestMutationError =
    | PostApiSsoAuthLoginAuthRequest401
    | PostApiSsoAuthLoginAuthRequest403
    | PostApiSsoAuthLoginAuthRequest422
    | PostApiSsoAuthLoginAuthRequest500;

/**
 * @summary Авторизация через форму
 */
export const usePostApiSsoAuthLoginAuthRequest = <
    TError =
        | PostApiSsoAuthLoginAuthRequest401
        | PostApiSsoAuthLoginAuthRequest403
        | PostApiSsoAuthLoginAuthRequest422
        | PostApiSsoAuthLoginAuthRequest500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiSsoAuthLoginAuthRequest>>,
        TError,
        {data: PostApiSsoAuthLoginAuthRequestBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiSsoAuthLoginAuthRequest>>,
    TError,
    {data: PostApiSsoAuthLoginAuthRequestBody},
    TContext
> => {
    const mutationOptions = getPostApiSsoAuthLoginAuthRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Проверка текущей сессии
 */
export const postApiSsoAuthCheckSession = (postApiSsoAuthCheckSessionBody: PostApiSsoAuthCheckSessionBody) => {
    return axiosInstance<PostApiSsoAuthCheckSession200>({
        url: `/api/sso/auth/checkSession`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: postApiSsoAuthCheckSessionBody
    });
};

export const getPostApiSsoAuthCheckSessionMutationOptions = <
    TError =
        | PostApiSsoAuthCheckSession401
        | PostApiSsoAuthCheckSession403
        | PostApiSsoAuthCheckSession422
        | PostApiSsoAuthCheckSession500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiSsoAuthCheckSession>>,
        TError,
        {data: PostApiSsoAuthCheckSessionBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiSsoAuthCheckSession>>,
    TError,
    {data: PostApiSsoAuthCheckSessionBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiSsoAuthCheckSession>>,
        {data: PostApiSsoAuthCheckSessionBody}
    > = (props) => {
        const {data} = props ?? {};

        return postApiSsoAuthCheckSession(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiSsoAuthCheckSessionMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiSsoAuthCheckSession>>
>;
export type PostApiSsoAuthCheckSessionMutationBody = PostApiSsoAuthCheckSessionBody;
export type PostApiSsoAuthCheckSessionMutationError =
    | PostApiSsoAuthCheckSession401
    | PostApiSsoAuthCheckSession403
    | PostApiSsoAuthCheckSession422
    | PostApiSsoAuthCheckSession500;

/**
 * @summary Проверка текущей сессии
 */
export const usePostApiSsoAuthCheckSession = <
    TError =
        | PostApiSsoAuthCheckSession401
        | PostApiSsoAuthCheckSession403
        | PostApiSsoAuthCheckSession422
        | PostApiSsoAuthCheckSession500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiSsoAuthCheckSession>>,
        TError,
        {data: PostApiSsoAuthCheckSessionBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiSsoAuthCheckSession>>,
    TError,
    {data: PostApiSsoAuthCheckSessionBody},
    TContext
> => {
    const mutationOptions = getPostApiSsoAuthCheckSessionMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Авторизация по токену пользователя
 */
export const postApiSsoAuthTokenAuthRequest = (
    postApiSsoAuthTokenAuthRequestBody: PostApiSsoAuthTokenAuthRequestBody
) => {
    return axiosInstance<PostApiSsoAuthTokenAuthRequest200>({
        url: `/api/sso/auth/tokenAuthRequest`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: postApiSsoAuthTokenAuthRequestBody
    });
};

export const getPostApiSsoAuthTokenAuthRequestMutationOptions = <
    TError =
        | PostApiSsoAuthTokenAuthRequest401
        | PostApiSsoAuthTokenAuthRequest403
        | PostApiSsoAuthTokenAuthRequest422
        | PostApiSsoAuthTokenAuthRequest500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiSsoAuthTokenAuthRequest>>,
        TError,
        {data: PostApiSsoAuthTokenAuthRequestBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiSsoAuthTokenAuthRequest>>,
    TError,
    {data: PostApiSsoAuthTokenAuthRequestBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiSsoAuthTokenAuthRequest>>,
        {data: PostApiSsoAuthTokenAuthRequestBody}
    > = (props) => {
        const {data} = props ?? {};

        return postApiSsoAuthTokenAuthRequest(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiSsoAuthTokenAuthRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiSsoAuthTokenAuthRequest>>
>;
export type PostApiSsoAuthTokenAuthRequestMutationBody = PostApiSsoAuthTokenAuthRequestBody;
export type PostApiSsoAuthTokenAuthRequestMutationError =
    | PostApiSsoAuthTokenAuthRequest401
    | PostApiSsoAuthTokenAuthRequest403
    | PostApiSsoAuthTokenAuthRequest422
    | PostApiSsoAuthTokenAuthRequest500;

/**
 * @summary Авторизация по токену пользователя
 */
export const usePostApiSsoAuthTokenAuthRequest = <
    TError =
        | PostApiSsoAuthTokenAuthRequest401
        | PostApiSsoAuthTokenAuthRequest403
        | PostApiSsoAuthTokenAuthRequest422
        | PostApiSsoAuthTokenAuthRequest500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiSsoAuthTokenAuthRequest>>,
        TError,
        {data: PostApiSsoAuthTokenAuthRequestBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiSsoAuthTokenAuthRequest>>,
    TError,
    {data: PostApiSsoAuthTokenAuthRequestBody},
    TContext
> => {
    const mutationOptions = getPostApiSsoAuthTokenAuthRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
