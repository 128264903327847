import {TypographyOptions} from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
    fontFamily: 'Inter Tight',
    h1: {fontWeight: 500, fontSize: '46px', lineHeight: '68px'},
    h2: {fontWeight: 500, fontSize: '38px', lineHeight: '56px'},
    h3: {
        fontWeight: 500,
        fontSize: '28px',
        lineHeight: '36px',
        '@media (max-width:600px)': {
            fontSize: 24,
            lineHeight: '32px'
        }
    },
    h4: {fontWeight: 500, fontSize: '24px', lineHeight: '38px'},
    h5: {fontWeight: 500, fontSize: '18px', lineHeight: '28px'},
    h6: {fontWeight: 500, fontSize: '15px', lineHeight: '22px'},
    body1: {fontWeight: 400, fontSize: '15px', lineHeight: '22px'},
    body2: {fontWeight: 400, fontSize: '13px', lineHeight: '20px'},

    button: {
        fontWeight: 500,
        textTransform: 'none',
        fontSize: '15px',
        lineHeight: '22px'
    }
};
