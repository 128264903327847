import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, Stack, Typography} from '@mui/material';
import {Paper} from 'components/accessory/Paper';
import {useForm} from 'react-hook-form';
import {Form} from 'components/form/Form';
import {FormTextField} from 'components/form/FormTextField';
import {MASKS} from 'utils/constants';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';

import {FormAutocompleteAsyncPaginate} from 'components/form/FormAutocompleteAsyncPaginate';

import EventBusyIcon from '@mui/icons-material/EventBusy';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

export const Scheduler = () => {
    const [isCustomTime, setIsCustomTime] = useState(false);
    const form = useForm();
    const {reset} = form;
    useEffect(() => {
        reset({timeFrom: '09:00', timeTo: '18:00'});
    }, []);

    return (
        <Stack gap={2} sx={{height: 'calc(100vh - 102px)'}}>
            <Paper sx={{padding: 2}}>
                <Form form={form}>
                    <Stack gap={3}>
                        <Stack gap={4} direction={'row'} flexWrap={'wrap'}>
                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                <IconButton
                                    onClick={() => setIsCustomTime(!isCustomTime)}
                                    color={isCustomTime ? 'primary' : 'default'}>
                                    <AccessAlarmsIcon />
                                </IconButton>
                                <FormTextField
                                    name={'timeFrom'}
                                    placeholder={'чч:мм'}
                                    sx={{width: 64}}
                                    maskProps={MASKS.TIME}
                                    closeIcon={false}
                                />
                                <Box>{'-'}</Box>
                                <FormTextField
                                    name={'timeTo'}
                                    placeholder={'чч:мм'}
                                    sx={{width: 64}}
                                    maskProps={MASKS.TIME}
                                    closeIcon={false}
                                />
                            </Stack>
                            <Button variant={'outlined'} startIcon={<EventBusyIcon />}>
                                Удаление смен
                            </Button>
                            <Button variant={'outlined'} startIcon={<EmojiPeopleIcon />}>
                                Запросы
                            </Button>
                            <Button variant={'outlined'} startIcon={<PersonAddAltIcon />}>
                                Приглашения
                            </Button>
                            <Button sx={{ml: 'auto'}} color={'warning'} variant={'contained'}>
                                Опубликовать
                            </Button>
                            <Button color={'error'} variant={'outlined'}>
                                Отмена
                            </Button>
                        </Stack>
                        <Stack gap={4} direction={'row'} sx={{width: 250}}>
                            <FormAutocompleteAsyncPaginate
                                name={'dot'}
                                placeholder={'Точка'}
                                options={[]}
                                hasNextPage={false}
                                sx={{width: 250}}
                            />
                            <FormAutocompleteAsyncPaginate
                                name={'positions'}
                                placeholder={'Должности'}
                                options={[]}
                                hasNextPage={false}
                                sx={{width: 250}}
                            />
                        </Stack>
                    </Stack>
                </Form>
            </Paper>
            <Paper sx={{p: 2}}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant={'h5'} color={'text.secondary'}>
                        Шуваловскй проспект 32
                    </Typography>
                    <Stack direction={'row'} gap={2}>
                        <Button variant={'text'} startIcon={<ArticleOutlinedIcon />} color={'success'}>
                            Табель
                        </Button>
                        <Button variant={'text'} startIcon={<ArticleOutlinedIcon />} color={'success'}>
                            Замены
                        </Button>
                        <Button variant={'text'} startIcon={<ArticleOutlinedIcon />} color={'success'}>
                            Daily
                        </Button>
                        <Button variant={'text'} color={'secondary'}>
                            Добавить сотрудника +
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
            <Paper sx={{p: 2, overflow: 'auto', gap: 2, height: '100%'}}>
                {/*<Stack*/}
                {/*    sx={{*/}
                {/*        width: 250,*/}
                {/*        border: (theme) => '1px solid ' + theme.palette.primary.light,*/}
                {/*        px: 1,*/}
                {/*        borderRadius: '8px'*/}
                {/*    }}>*/}
                {/*    <MonthCarousel />*/}
                {/*</Stack>*/}
                {/*<Stack mt={2} direction={'row'} sx={{gap: 2}}>*/}
                {/*    <Stack*/}
                {/*        width={{*/}
                {/*            minWidth: 265,*/}
                {/*            position: 'sticky',*/}
                {/*            height: '100%',*/}
                {/*            left: 0*/}
                {/*        }}></Stack>*/}
                {/*    <CalendarHeader />*/}
                {/*</Stack>*/}
                {/*<CalendarRow />*/}
            </Paper>
        </Stack>
    );
};
