import {CSSObject, Theme} from '@mui/material/styles';

export const transitionMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    })
});

export const paperPaddingMixin = {p: 3};

export const logoColorMixin = (thisTheme: 'dark' | 'light') => ({
    '& path': {
        fill: (theme: Theme) =>
            (thisTheme === 'dark' ? theme.palette.text.primary : theme.palette.secondary.main) + ' !important'
    },
    '& rect': {
        stroke: (theme: Theme) =>
            (thisTheme === 'dark' ? theme.palette.text.primary : theme.palette.secondary.main) + ' !important'
    }
});
export const shadow = '0 .125rem .5rem 0 rgba(47,43,61,.12)';
export const svgColorMixin = (color: string) => ({'& path, rect, circle': {stroke: color}});
