/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BANDA API
 * OpenAPI spec version: 1.0.0
 */
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult
} from '@tanstack/react-query';
import {useMutation, useQuery} from '@tanstack/react-query';
import type {
    GetApiBandaSystemAccessContentGroupGroups200,
    GetApiBandaSystemAccessContentGroupGroups401,
    GetApiBandaSystemAccessContentGroupGroups403,
    GetApiBandaSystemAccessContentGroupGroups422,
    GetApiBandaSystemAccessesGroupsShow200,
    GetApiBandaSystemAccessesGroupsShow401,
    GetApiBandaSystemAccessesGroupsShow403,
    GetApiBandaSystemAccessesGroupsShow422,
    GetApiBandaSystemAccessesGroupsShowBody,
    GetApiBandaSystemAccessesGroupsShowParams,
    GetApiBandaSystemAccessesPermissions200,
    GetApiBandaSystemAccessesPermissions401,
    GetApiBandaSystemAccessesPermissions403,
    GetApiBandaSystemAccessesPermissions422,
    GetApiBandaSystemAccessesPermissionsBody,
    GetApiBandaSystemAccessesPermissionsParams,
    GetApiBandaSystemAccessesRoles200,
    GetApiBandaSystemAccessesRoles401,
    GetApiBandaSystemAccessesRoles403,
    GetApiBandaSystemAccessesRoles422,
    GetApiBandaSystemAccessesRolesBody,
    GetApiBandaSystemAccessesRolesParams,
    GetApiBandaSystemDirectoryDepartments200,
    GetApiBandaSystemDirectoryDepartments401,
    GetApiBandaSystemDirectoryDepartments403,
    GetApiBandaSystemDirectoryEmployees200,
    GetApiBandaSystemDirectoryEmployees401,
    GetApiBandaSystemDirectoryEmployees403,
    GetApiBandaSystemDirectoryPositions200,
    GetApiBandaSystemDirectoryPositions401,
    GetApiBandaSystemDirectoryPositions403,
    GetApiBandaSystemEmployeesAccountsShow200,
    GetApiBandaSystemEmployeesAccountsShow401,
    GetApiBandaSystemEmployeesAccountsShow403,
    GetApiBandaSystemEmployeesAccountsShow422,
    GetApiBandaSystemEmployeesAccountsShow500,
    GetApiBandaSystemEmployeesAccountsShowBody,
    GetApiBandaSystemEmployeesAccountsShowParams,
    GetApiBandaSystemEmployeesServiceAccountsShow401,
    GetApiBandaSystemEmployeesServiceAccountsShow403,
    GetApiBandaSystemEmployeesServiceAccountsShow422,
    GetApiBandaSystemEmployeesServiceAccountsShow500,
    GetApiBandaSystemEmployeesServiceAccountsShowParams,
    PatchApiBandaSystemAccessContentGroupEdit200,
    PatchApiBandaSystemAccessContentGroupEdit401,
    PatchApiBandaSystemAccessContentGroupEdit403,
    PatchApiBandaSystemAccessContentGroupEdit422,
    PatchApiBandaSystemAccessContentGroupEditBody,
    PatchApiBandaSystemAccessesGroupsUpdate200,
    PatchApiBandaSystemAccessesGroupsUpdate401,
    PatchApiBandaSystemAccessesGroupsUpdate403,
    PatchApiBandaSystemAccessesGroupsUpdate422,
    PatchApiBandaSystemAccessesGroupsUpdateBody,
    PatchApiBandaSystemAccessesRoleUpdate200,
    PatchApiBandaSystemAccessesRoleUpdate401,
    PatchApiBandaSystemAccessesRoleUpdate403,
    PatchApiBandaSystemAccessesRoleUpdate422,
    PatchApiBandaSystemAccessesRoleUpdateBody,
    PatchApiBandaSystemEmployeesServiceAccountsUpdate401,
    PatchApiBandaSystemEmployeesServiceAccountsUpdate403,
    PatchApiBandaSystemEmployeesServiceAccountsUpdate422,
    PatchApiBandaSystemEmployeesServiceAccountsUpdate500,
    PutApiBandaSystemAccessContentGroupCreate200,
    PutApiBandaSystemAccessContentGroupCreate401,
    PutApiBandaSystemAccessContentGroupCreate403,
    PutApiBandaSystemAccessContentGroupCreate422,
    PutApiBandaSystemAccessContentGroupCreateBody,
    PutApiBandaSystemAccessesGroupsCreate200,
    PutApiBandaSystemAccessesGroupsCreate401,
    PutApiBandaSystemAccessesGroupsCreate403,
    PutApiBandaSystemAccessesGroupsCreate422,
    PutApiBandaSystemAccessesGroupsCreateBody,
    PutApiBandaSystemAccessesRoleCreate200,
    PutApiBandaSystemAccessesRoleCreate401,
    PutApiBandaSystemAccessesRoleCreate403,
    PutApiBandaSystemAccessesRoleCreate422,
    PutApiBandaSystemAccessesRoleCreateBody,
    PutApiBandaSystemEmployeesServiceAccountsCreate401,
    PutApiBandaSystemEmployeesServiceAccountsCreate403,
    PutApiBandaSystemEmployeesServiceAccountsCreate422,
    PutApiBandaSystemEmployeesServiceAccountsCreate500
} from './bandaApi.schemas';
import {axiosInstance} from './mutator/axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Справочник групп
 */
export const getApiBandaSystemAccessContentGroupGroups = (signal?: AbortSignal) => {
    return axiosInstance<GetApiBandaSystemAccessContentGroupGroups200>({
        url: `/api/banda/system/access_content_group/groups`,
        method: 'GET',
        signal
    });
};

export const getGetApiBandaSystemAccessContentGroupGroupsQueryKey = () => {
    return [`/api/banda/system/access_content_group/groups`] as const;
};

export const getGetApiBandaSystemAccessContentGroupGroupsQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessContentGroupGroups>>,
    TError =
        | GetApiBandaSystemAccessContentGroupGroups401
        | GetApiBandaSystemAccessContentGroupGroups403
        | GetApiBandaSystemAccessContentGroupGroups422
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessContentGroupGroups>>, TError, TData>
    >;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemAccessContentGroupGroupsQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemAccessContentGroupGroups>>> = ({signal}) =>
        getApiBandaSystemAccessContentGroupGroups(signal);

    return {
        queryKey,
        queryFn,
        ...queryOptions
    } as UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessContentGroupGroups>>, TError, TData> & {
        queryKey: QueryKey;
    };
};

export type GetApiBandaSystemAccessContentGroupGroupsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemAccessContentGroupGroups>>
>;
export type GetApiBandaSystemAccessContentGroupGroupsQueryError =
    | GetApiBandaSystemAccessContentGroupGroups401
    | GetApiBandaSystemAccessContentGroupGroups403
    | GetApiBandaSystemAccessContentGroupGroups422;

/**
 * @summary Справочник групп
 */
export const useGetApiBandaSystemAccessContentGroupGroups = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessContentGroupGroups>>,
    TError =
        | GetApiBandaSystemAccessContentGroupGroups401
        | GetApiBandaSystemAccessContentGroupGroups403
        | GetApiBandaSystemAccessContentGroupGroups422
>(options?: {
    query?: Partial<
        UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessContentGroupGroups>>, TError, TData>
    >;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemAccessContentGroupGroupsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Создание группы
 */
export const putApiBandaSystemAccessContentGroupCreate = (
    putApiBandaSystemAccessContentGroupCreateBody: PutApiBandaSystemAccessContentGroupCreateBody
) => {
    return axiosInstance<PutApiBandaSystemAccessContentGroupCreate200>({
        url: `/api/banda/system/access_content_group/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaSystemAccessContentGroupCreateBody
    });
};

export const getPutApiBandaSystemAccessContentGroupCreateMutationOptions = <
    TError =
        | PutApiBandaSystemAccessContentGroupCreate401
        | PutApiBandaSystemAccessContentGroupCreate403
        | PutApiBandaSystemAccessContentGroupCreate422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemAccessContentGroupCreate>>,
        TError,
        {data: PutApiBandaSystemAccessContentGroupCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaSystemAccessContentGroupCreate>>,
    TError,
    {data: PutApiBandaSystemAccessContentGroupCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaSystemAccessContentGroupCreate>>,
        {data: PutApiBandaSystemAccessContentGroupCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaSystemAccessContentGroupCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaSystemAccessContentGroupCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaSystemAccessContentGroupCreate>>
>;
export type PutApiBandaSystemAccessContentGroupCreateMutationBody = PutApiBandaSystemAccessContentGroupCreateBody;
export type PutApiBandaSystemAccessContentGroupCreateMutationError =
    | PutApiBandaSystemAccessContentGroupCreate401
    | PutApiBandaSystemAccessContentGroupCreate403
    | PutApiBandaSystemAccessContentGroupCreate422;

/**
 * @summary Создание группы
 */
export const usePutApiBandaSystemAccessContentGroupCreate = <
    TError =
        | PutApiBandaSystemAccessContentGroupCreate401
        | PutApiBandaSystemAccessContentGroupCreate403
        | PutApiBandaSystemAccessContentGroupCreate422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemAccessContentGroupCreate>>,
        TError,
        {data: PutApiBandaSystemAccessContentGroupCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaSystemAccessContentGroupCreate>>,
    TError,
    {data: PutApiBandaSystemAccessContentGroupCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaSystemAccessContentGroupCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Редактирование группы
 */
export const patchApiBandaSystemAccessContentGroupEdit = (
    patchApiBandaSystemAccessContentGroupEditBody: PatchApiBandaSystemAccessContentGroupEditBody
) => {
    return axiosInstance<PatchApiBandaSystemAccessContentGroupEdit200>({
        url: `/api/banda/system/access_content_group/edit`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemAccessContentGroupEditBody
    });
};

export const getPatchApiBandaSystemAccessContentGroupEditMutationOptions = <
    TError =
        | PatchApiBandaSystemAccessContentGroupEdit401
        | PatchApiBandaSystemAccessContentGroupEdit403
        | PatchApiBandaSystemAccessContentGroupEdit422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessContentGroupEdit>>,
        TError,
        {data: PatchApiBandaSystemAccessContentGroupEditBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessContentGroupEdit>>,
    TError,
    {data: PatchApiBandaSystemAccessContentGroupEditBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessContentGroupEdit>>,
        {data: PatchApiBandaSystemAccessContentGroupEditBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemAccessContentGroupEdit(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemAccessContentGroupEditMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessContentGroupEdit>>
>;
export type PatchApiBandaSystemAccessContentGroupEditMutationBody = PatchApiBandaSystemAccessContentGroupEditBody;
export type PatchApiBandaSystemAccessContentGroupEditMutationError =
    | PatchApiBandaSystemAccessContentGroupEdit401
    | PatchApiBandaSystemAccessContentGroupEdit403
    | PatchApiBandaSystemAccessContentGroupEdit422;

/**
 * @summary Редактирование группы
 */
export const usePatchApiBandaSystemAccessContentGroupEdit = <
    TError =
        | PatchApiBandaSystemAccessContentGroupEdit401
        | PatchApiBandaSystemAccessContentGroupEdit403
        | PatchApiBandaSystemAccessContentGroupEdit422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessContentGroupEdit>>,
        TError,
        {data: PatchApiBandaSystemAccessContentGroupEditBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessContentGroupEdit>>,
    TError,
    {data: PatchApiBandaSystemAccessContentGroupEditBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemAccessContentGroupEditMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Аккаунты
 */
export const getApiBandaSystemEmployeesAccountsShow = (
    getApiBandaSystemEmployeesAccountsShowBody?: GetApiBandaSystemEmployeesAccountsShowBody,
    params?: GetApiBandaSystemEmployeesAccountsShowParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemEmployeesAccountsShow200>({
        url: `/api/banda/system/employees/accounts/show`,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        params,
        signal
    });
};

export const getGetApiBandaSystemEmployeesAccountsShowQueryKey = (
    getApiBandaSystemEmployeesAccountsShowBody?: GetApiBandaSystemEmployeesAccountsShowBody,
    params?: GetApiBandaSystemEmployeesAccountsShowParams
) => {
    return [
        `/api/banda/system/employees/accounts/show`,
        ...(params ? [params] : []),
        getApiBandaSystemEmployeesAccountsShowBody
    ] as const;
};

export const getGetApiBandaSystemEmployeesAccountsShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsShow>>,
    TError =
        | GetApiBandaSystemEmployeesAccountsShow401
        | GetApiBandaSystemEmployeesAccountsShow403
        | GetApiBandaSystemEmployeesAccountsShow422
        | GetApiBandaSystemEmployeesAccountsShow500
>(
    getApiBandaSystemEmployeesAccountsShowBody?: GetApiBandaSystemEmployeesAccountsShowBody,
    params?: GetApiBandaSystemEmployeesAccountsShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsShow>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetApiBandaSystemEmployeesAccountsShowQueryKey(getApiBandaSystemEmployeesAccountsShowBody, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsShow>>> = ({signal}) =>
        getApiBandaSystemEmployeesAccountsShow(getApiBandaSystemEmployeesAccountsShowBody, params, signal);

    return {
        queryKey,
        queryFn,
        ...queryOptions
    } as UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsShow>>, TError, TData> & {
        queryKey: QueryKey;
    };
};

export type GetApiBandaSystemEmployeesAccountsShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsShow>>
>;
export type GetApiBandaSystemEmployeesAccountsShowQueryError =
    | GetApiBandaSystemEmployeesAccountsShow401
    | GetApiBandaSystemEmployeesAccountsShow403
    | GetApiBandaSystemEmployeesAccountsShow422
    | GetApiBandaSystemEmployeesAccountsShow500;

/**
 * @summary Аккаунты
 */
export const useGetApiBandaSystemEmployeesAccountsShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsShow>>,
    TError =
        | GetApiBandaSystemEmployeesAccountsShow401
        | GetApiBandaSystemEmployeesAccountsShow403
        | GetApiBandaSystemEmployeesAccountsShow422
        | GetApiBandaSystemEmployeesAccountsShow500
>(
    getApiBandaSystemEmployeesAccountsShowBody?: GetApiBandaSystemEmployeesAccountsShowBody,
    params?: GetApiBandaSystemEmployeesAccountsShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesAccountsShow>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemEmployeesAccountsShowQueryOptions(
        getApiBandaSystemEmployeesAccountsShowBody,
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Список сервисных аккаунты
 */
export const getApiBandaSystemEmployeesServiceAccountsShow = (
    params?: GetApiBandaSystemEmployeesServiceAccountsShowParams,
    signal?: AbortSignal
) => {
    return axiosInstance<unknown>({
        url: `/api/banda/system/employees/service_accounts/show`,
        method: 'GET',
        params,
        signal
    });
};

export const getGetApiBandaSystemEmployeesServiceAccountsShowQueryKey = (
    params?: GetApiBandaSystemEmployeesServiceAccountsShowParams
) => {
    return [`/api/banda/system/employees/service_accounts/show`, ...(params ? [params] : [])] as const;
};

export const getGetApiBandaSystemEmployeesServiceAccountsShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesServiceAccountsShow>>,
    TError =
        | GetApiBandaSystemEmployeesServiceAccountsShow401
        | GetApiBandaSystemEmployeesServiceAccountsShow403
        | GetApiBandaSystemEmployeesServiceAccountsShow422
        | GetApiBandaSystemEmployeesServiceAccountsShow500
>(
    params?: GetApiBandaSystemEmployeesServiceAccountsShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesServiceAccountsShow>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemEmployeesServiceAccountsShowQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemEmployeesServiceAccountsShow>>> = ({
        signal
    }) => getApiBandaSystemEmployeesServiceAccountsShow(params, signal);

    return {
        queryKey,
        queryFn,
        ...queryOptions
    } as UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesServiceAccountsShow>>, TError, TData> & {
        queryKey: QueryKey;
    };
};

export type GetApiBandaSystemEmployeesServiceAccountsShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemEmployeesServiceAccountsShow>>
>;
export type GetApiBandaSystemEmployeesServiceAccountsShowQueryError =
    | GetApiBandaSystemEmployeesServiceAccountsShow401
    | GetApiBandaSystemEmployeesServiceAccountsShow403
    | GetApiBandaSystemEmployeesServiceAccountsShow422
    | GetApiBandaSystemEmployeesServiceAccountsShow500;

/**
 * @summary Список сервисных аккаунты
 */
export const useGetApiBandaSystemEmployeesServiceAccountsShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemEmployeesServiceAccountsShow>>,
    TError =
        | GetApiBandaSystemEmployeesServiceAccountsShow401
        | GetApiBandaSystemEmployeesServiceAccountsShow403
        | GetApiBandaSystemEmployeesServiceAccountsShow422
        | GetApiBandaSystemEmployeesServiceAccountsShow500
>(
    params?: GetApiBandaSystemEmployeesServiceAccountsShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemEmployeesServiceAccountsShow>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemEmployeesServiceAccountsShowQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Создать сервисный аккаунт
 */
export const putApiBandaSystemEmployeesServiceAccountsCreate = () => {
    return axiosInstance<unknown>({url: `/api/banda/system/employees/service_accounts/create`, method: 'PUT'});
};

export const getPutApiBandaSystemEmployeesServiceAccountsCreateMutationOptions = <
    TError =
        | PutApiBandaSystemEmployeesServiceAccountsCreate401
        | PutApiBandaSystemEmployeesServiceAccountsCreate403
        | PutApiBandaSystemEmployeesServiceAccountsCreate422
        | PutApiBandaSystemEmployeesServiceAccountsCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesServiceAccountsCreate>>,
        TError,
        void,
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesServiceAccountsCreate>>,
    TError,
    void,
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesServiceAccountsCreate>>,
        void
    > = () => {
        return putApiBandaSystemEmployeesServiceAccountsCreate();
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaSystemEmployeesServiceAccountsCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesServiceAccountsCreate>>
>;

export type PutApiBandaSystemEmployeesServiceAccountsCreateMutationError =
    | PutApiBandaSystemEmployeesServiceAccountsCreate401
    | PutApiBandaSystemEmployeesServiceAccountsCreate403
    | PutApiBandaSystemEmployeesServiceAccountsCreate422
    | PutApiBandaSystemEmployeesServiceAccountsCreate500;

/**
 * @summary Создать сервисный аккаунт
 */
export const usePutApiBandaSystemEmployeesServiceAccountsCreate = <
    TError =
        | PutApiBandaSystemEmployeesServiceAccountsCreate401
        | PutApiBandaSystemEmployeesServiceAccountsCreate403
        | PutApiBandaSystemEmployeesServiceAccountsCreate422
        | PutApiBandaSystemEmployeesServiceAccountsCreate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemEmployeesServiceAccountsCreate>>,
        TError,
        void,
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaSystemEmployeesServiceAccountsCreate>>,
    TError,
    void,
    TContext
> => {
    const mutationOptions = getPutApiBandaSystemEmployeesServiceAccountsCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Редактирование сервисного аккаунта
 */
export const patchApiBandaSystemEmployeesServiceAccountsUpdate = () => {
    return axiosInstance<unknown>({url: `/api/banda/system/employees/service_accounts/update`, method: 'PATCH'});
};

export const getPatchApiBandaSystemEmployeesServiceAccountsUpdateMutationOptions = <
    TError =
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate401
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate403
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate422
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesServiceAccountsUpdate>>,
        TError,
        void,
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesServiceAccountsUpdate>>,
    TError,
    void,
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesServiceAccountsUpdate>>,
        void
    > = () => {
        return patchApiBandaSystemEmployeesServiceAccountsUpdate();
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemEmployeesServiceAccountsUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesServiceAccountsUpdate>>
>;

export type PatchApiBandaSystemEmployeesServiceAccountsUpdateMutationError =
    | PatchApiBandaSystemEmployeesServiceAccountsUpdate401
    | PatchApiBandaSystemEmployeesServiceAccountsUpdate403
    | PatchApiBandaSystemEmployeesServiceAccountsUpdate422
    | PatchApiBandaSystemEmployeesServiceAccountsUpdate500;

/**
 * @summary Редактирование сервисного аккаунта
 */
export const usePatchApiBandaSystemEmployeesServiceAccountsUpdate = <
    TError =
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate401
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate403
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate422
        | PatchApiBandaSystemEmployeesServiceAccountsUpdate500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemEmployeesServiceAccountsUpdate>>,
        TError,
        void,
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemEmployeesServiceAccountsUpdate>>,
    TError,
    void,
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemEmployeesServiceAccountsUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Список прав
 */
export const getApiBandaSystemAccessesPermissions = (
    getApiBandaSystemAccessesPermissionsBody?: GetApiBandaSystemAccessesPermissionsBody,
    params?: GetApiBandaSystemAccessesPermissionsParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemAccessesPermissions200>({
        url: `/api/banda/system/accesses/permissions`,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        params,
        signal
    });
};

export const getGetApiBandaSystemAccessesPermissionsQueryKey = (
    getApiBandaSystemAccessesPermissionsBody?: GetApiBandaSystemAccessesPermissionsBody,
    params?: GetApiBandaSystemAccessesPermissionsParams
) => {
    return [
        `/api/banda/system/accesses/permissions`,
        ...(params ? [params] : []),
        getApiBandaSystemAccessesPermissionsBody
    ] as const;
};

export const getGetApiBandaSystemAccessesPermissionsQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessesPermissions>>,
    TError =
        | GetApiBandaSystemAccessesPermissions401
        | GetApiBandaSystemAccessesPermissions403
        | GetApiBandaSystemAccessesPermissions422
>(
    getApiBandaSystemAccessesPermissionsBody?: GetApiBandaSystemAccessesPermissionsBody,
    params?: GetApiBandaSystemAccessesPermissionsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessesPermissions>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetApiBandaSystemAccessesPermissionsQueryKey(getApiBandaSystemAccessesPermissionsBody, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemAccessesPermissions>>> = ({signal}) =>
        getApiBandaSystemAccessesPermissions(getApiBandaSystemAccessesPermissionsBody, params, signal);

    return {
        queryKey,
        queryFn,
        ...queryOptions
    } as UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessesPermissions>>, TError, TData> & {
        queryKey: QueryKey;
    };
};

export type GetApiBandaSystemAccessesPermissionsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemAccessesPermissions>>
>;
export type GetApiBandaSystemAccessesPermissionsQueryError =
    | GetApiBandaSystemAccessesPermissions401
    | GetApiBandaSystemAccessesPermissions403
    | GetApiBandaSystemAccessesPermissions422;

/**
 * @summary Список прав
 */
export const useGetApiBandaSystemAccessesPermissions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessesPermissions>>,
    TError =
        | GetApiBandaSystemAccessesPermissions401
        | GetApiBandaSystemAccessesPermissions403
        | GetApiBandaSystemAccessesPermissions422
>(
    getApiBandaSystemAccessesPermissionsBody?: GetApiBandaSystemAccessesPermissionsBody,
    params?: GetApiBandaSystemAccessesPermissionsParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessesPermissions>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemAccessesPermissionsQueryOptions(
        getApiBandaSystemAccessesPermissionsBody,
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Список ролей
 */
export const getApiBandaSystemAccessesRoles = (
    getApiBandaSystemAccessesRolesBody?: GetApiBandaSystemAccessesRolesBody,
    params?: GetApiBandaSystemAccessesRolesParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemAccessesRoles200>({
        url: `/api/banda/system/accesses/roles`,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        params,
        signal
    });
};

export const getGetApiBandaSystemAccessesRolesQueryKey = (
    getApiBandaSystemAccessesRolesBody?: GetApiBandaSystemAccessesRolesBody,
    params?: GetApiBandaSystemAccessesRolesParams
) => {
    return [
        `/api/banda/system/accesses/roles`,
        ...(params ? [params] : []),
        getApiBandaSystemAccessesRolesBody
    ] as const;
};

export const getGetApiBandaSystemAccessesRolesQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessesRoles>>,
    TError = GetApiBandaSystemAccessesRoles401 | GetApiBandaSystemAccessesRoles403 | GetApiBandaSystemAccessesRoles422
>(
    getApiBandaSystemAccessesRolesBody?: GetApiBandaSystemAccessesRolesBody,
    params?: GetApiBandaSystemAccessesRolesParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessesRoles>>, TError, TData>>;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetApiBandaSystemAccessesRolesQueryKey(getApiBandaSystemAccessesRolesBody, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemAccessesRoles>>> = ({signal}) =>
        getApiBandaSystemAccessesRoles(getApiBandaSystemAccessesRolesBody, params, signal);

    return {
        queryKey,
        queryFn,
        ...queryOptions
    } as UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessesRoles>>, TError, TData> & {
        queryKey: QueryKey;
    };
};

export type GetApiBandaSystemAccessesRolesQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemAccessesRoles>>
>;
export type GetApiBandaSystemAccessesRolesQueryError =
    | GetApiBandaSystemAccessesRoles401
    | GetApiBandaSystemAccessesRoles403
    | GetApiBandaSystemAccessesRoles422;

/**
 * @summary Список ролей
 */
export const useGetApiBandaSystemAccessesRoles = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessesRoles>>,
    TError = GetApiBandaSystemAccessesRoles401 | GetApiBandaSystemAccessesRoles403 | GetApiBandaSystemAccessesRoles422
>(
    getApiBandaSystemAccessesRolesBody?: GetApiBandaSystemAccessesRolesBody,
    params?: GetApiBandaSystemAccessesRolesParams,
    options?: {
        query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessesRoles>>, TError, TData>>;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemAccessesRolesQueryOptions(
        getApiBandaSystemAccessesRolesBody,
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * В поле permissions добавляем слаг права, а если право имеет опции, то передаем опцию так {слаг}:{ключ опции}, пример news.create:all
 * @summary Создание роли
 */
export const putApiBandaSystemAccessesRoleCreate = (
    putApiBandaSystemAccessesRoleCreateBody: PutApiBandaSystemAccessesRoleCreateBody
) => {
    return axiosInstance<PutApiBandaSystemAccessesRoleCreate200>({
        url: `/api/banda/system/accesses/role/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaSystemAccessesRoleCreateBody
    });
};

export const getPutApiBandaSystemAccessesRoleCreateMutationOptions = <
    TError =
        | PutApiBandaSystemAccessesRoleCreate401
        | PutApiBandaSystemAccessesRoleCreate403
        | PutApiBandaSystemAccessesRoleCreate422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemAccessesRoleCreate>>,
        TError,
        {data: PutApiBandaSystemAccessesRoleCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaSystemAccessesRoleCreate>>,
    TError,
    {data: PutApiBandaSystemAccessesRoleCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaSystemAccessesRoleCreate>>,
        {data: PutApiBandaSystemAccessesRoleCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaSystemAccessesRoleCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaSystemAccessesRoleCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaSystemAccessesRoleCreate>>
>;
export type PutApiBandaSystemAccessesRoleCreateMutationBody = PutApiBandaSystemAccessesRoleCreateBody;
export type PutApiBandaSystemAccessesRoleCreateMutationError =
    | PutApiBandaSystemAccessesRoleCreate401
    | PutApiBandaSystemAccessesRoleCreate403
    | PutApiBandaSystemAccessesRoleCreate422;

/**
 * @summary Создание роли
 */
export const usePutApiBandaSystemAccessesRoleCreate = <
    TError =
        | PutApiBandaSystemAccessesRoleCreate401
        | PutApiBandaSystemAccessesRoleCreate403
        | PutApiBandaSystemAccessesRoleCreate422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemAccessesRoleCreate>>,
        TError,
        {data: PutApiBandaSystemAccessesRoleCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaSystemAccessesRoleCreate>>,
    TError,
    {data: PutApiBandaSystemAccessesRoleCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaSystemAccessesRoleCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * В поле permissions добавляем слаг права, а если право имеет опции, то передаем опцию так {слаг}:{ключ опции}, пример news.create:all
 * @summary Редактирование роли
 */
export const patchApiBandaSystemAccessesRoleUpdate = (
    patchApiBandaSystemAccessesRoleUpdateBody: PatchApiBandaSystemAccessesRoleUpdateBody
) => {
    return axiosInstance<PatchApiBandaSystemAccessesRoleUpdate200>({
        url: `/api/banda/system/accesses/role/update`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemAccessesRoleUpdateBody
    });
};

export const getPatchApiBandaSystemAccessesRoleUpdateMutationOptions = <
    TError =
        | PatchApiBandaSystemAccessesRoleUpdate401
        | PatchApiBandaSystemAccessesRoleUpdate403
        | PatchApiBandaSystemAccessesRoleUpdate422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessesRoleUpdate>>,
        TError,
        {data: PatchApiBandaSystemAccessesRoleUpdateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessesRoleUpdate>>,
    TError,
    {data: PatchApiBandaSystemAccessesRoleUpdateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessesRoleUpdate>>,
        {data: PatchApiBandaSystemAccessesRoleUpdateBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemAccessesRoleUpdate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemAccessesRoleUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessesRoleUpdate>>
>;
export type PatchApiBandaSystemAccessesRoleUpdateMutationBody = PatchApiBandaSystemAccessesRoleUpdateBody;
export type PatchApiBandaSystemAccessesRoleUpdateMutationError =
    | PatchApiBandaSystemAccessesRoleUpdate401
    | PatchApiBandaSystemAccessesRoleUpdate403
    | PatchApiBandaSystemAccessesRoleUpdate422;

/**
 * @summary Редактирование роли
 */
export const usePatchApiBandaSystemAccessesRoleUpdate = <
    TError =
        | PatchApiBandaSystemAccessesRoleUpdate401
        | PatchApiBandaSystemAccessesRoleUpdate403
        | PatchApiBandaSystemAccessesRoleUpdate422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessesRoleUpdate>>,
        TError,
        {data: PatchApiBandaSystemAccessesRoleUpdateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessesRoleUpdate>>,
    TError,
    {data: PatchApiBandaSystemAccessesRoleUpdateBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemAccessesRoleUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Список групп
 */
export const getApiBandaSystemAccessesGroupsShow = (
    getApiBandaSystemAccessesGroupsShowBody?: GetApiBandaSystemAccessesGroupsShowBody,
    params?: GetApiBandaSystemAccessesGroupsShowParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetApiBandaSystemAccessesGroupsShow200>({
        url: `/api/banda/system/accesses/groups/show`,
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
        params,
        signal
    });
};

export const getGetApiBandaSystemAccessesGroupsShowQueryKey = (
    getApiBandaSystemAccessesGroupsShowBody?: GetApiBandaSystemAccessesGroupsShowBody,
    params?: GetApiBandaSystemAccessesGroupsShowParams
) => {
    return [
        `/api/banda/system/accesses/groups/show`,
        ...(params ? [params] : []),
        getApiBandaSystemAccessesGroupsShowBody
    ] as const;
};

export const getGetApiBandaSystemAccessesGroupsShowQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessesGroupsShow>>,
    TError =
        | GetApiBandaSystemAccessesGroupsShow401
        | GetApiBandaSystemAccessesGroupsShow403
        | GetApiBandaSystemAccessesGroupsShow422
>(
    getApiBandaSystemAccessesGroupsShowBody?: GetApiBandaSystemAccessesGroupsShowBody,
    params?: GetApiBandaSystemAccessesGroupsShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessesGroupsShow>>, TError, TData>
        >;
    }
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetApiBandaSystemAccessesGroupsShowQueryKey(getApiBandaSystemAccessesGroupsShowBody, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemAccessesGroupsShow>>> = ({signal}) =>
        getApiBandaSystemAccessesGroupsShow(getApiBandaSystemAccessesGroupsShowBody, params, signal);

    return {
        queryKey,
        queryFn,
        ...queryOptions
    } as UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessesGroupsShow>>, TError, TData> & {
        queryKey: QueryKey;
    };
};

export type GetApiBandaSystemAccessesGroupsShowQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemAccessesGroupsShow>>
>;
export type GetApiBandaSystemAccessesGroupsShowQueryError =
    | GetApiBandaSystemAccessesGroupsShow401
    | GetApiBandaSystemAccessesGroupsShow403
    | GetApiBandaSystemAccessesGroupsShow422;

/**
 * @summary Список групп
 */
export const useGetApiBandaSystemAccessesGroupsShow = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemAccessesGroupsShow>>,
    TError =
        | GetApiBandaSystemAccessesGroupsShow401
        | GetApiBandaSystemAccessesGroupsShow403
        | GetApiBandaSystemAccessesGroupsShow422
>(
    getApiBandaSystemAccessesGroupsShowBody?: GetApiBandaSystemAccessesGroupsShowBody,
    params?: GetApiBandaSystemAccessesGroupsShowParams,
    options?: {
        query?: Partial<
            UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemAccessesGroupsShow>>, TError, TData>
        >;
    }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemAccessesGroupsShowQueryOptions(
        getApiBandaSystemAccessesGroupsShowBody,
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Создание группы
 */
export const putApiBandaSystemAccessesGroupsCreate = (
    putApiBandaSystemAccessesGroupsCreateBody: PutApiBandaSystemAccessesGroupsCreateBody
) => {
    return axiosInstance<PutApiBandaSystemAccessesGroupsCreate200>({
        url: `/api/banda/system/accesses/groups/create`,
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        data: putApiBandaSystemAccessesGroupsCreateBody
    });
};

export const getPutApiBandaSystemAccessesGroupsCreateMutationOptions = <
    TError =
        | PutApiBandaSystemAccessesGroupsCreate401
        | PutApiBandaSystemAccessesGroupsCreate403
        | PutApiBandaSystemAccessesGroupsCreate422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemAccessesGroupsCreate>>,
        TError,
        {data: PutApiBandaSystemAccessesGroupsCreateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof putApiBandaSystemAccessesGroupsCreate>>,
    TError,
    {data: PutApiBandaSystemAccessesGroupsCreateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof putApiBandaSystemAccessesGroupsCreate>>,
        {data: PutApiBandaSystemAccessesGroupsCreateBody}
    > = (props) => {
        const {data} = props ?? {};

        return putApiBandaSystemAccessesGroupsCreate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PutApiBandaSystemAccessesGroupsCreateMutationResult = NonNullable<
    Awaited<ReturnType<typeof putApiBandaSystemAccessesGroupsCreate>>
>;
export type PutApiBandaSystemAccessesGroupsCreateMutationBody = PutApiBandaSystemAccessesGroupsCreateBody;
export type PutApiBandaSystemAccessesGroupsCreateMutationError =
    | PutApiBandaSystemAccessesGroupsCreate401
    | PutApiBandaSystemAccessesGroupsCreate403
    | PutApiBandaSystemAccessesGroupsCreate422;

/**
 * @summary Создание группы
 */
export const usePutApiBandaSystemAccessesGroupsCreate = <
    TError =
        | PutApiBandaSystemAccessesGroupsCreate401
        | PutApiBandaSystemAccessesGroupsCreate403
        | PutApiBandaSystemAccessesGroupsCreate422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof putApiBandaSystemAccessesGroupsCreate>>,
        TError,
        {data: PutApiBandaSystemAccessesGroupsCreateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof putApiBandaSystemAccessesGroupsCreate>>,
    TError,
    {data: PutApiBandaSystemAccessesGroupsCreateBody},
    TContext
> => {
    const mutationOptions = getPutApiBandaSystemAccessesGroupsCreateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Редактирование группы
 */
export const patchApiBandaSystemAccessesGroupsUpdate = (
    patchApiBandaSystemAccessesGroupsUpdateBody: PatchApiBandaSystemAccessesGroupsUpdateBody
) => {
    return axiosInstance<PatchApiBandaSystemAccessesGroupsUpdate200>({
        url: `/api/banda/system/accesses/groups/update`,
        method: 'PATCH',
        headers: {'Content-Type': 'application/json'},
        data: patchApiBandaSystemAccessesGroupsUpdateBody
    });
};

export const getPatchApiBandaSystemAccessesGroupsUpdateMutationOptions = <
    TError =
        | PatchApiBandaSystemAccessesGroupsUpdate401
        | PatchApiBandaSystemAccessesGroupsUpdate403
        | PatchApiBandaSystemAccessesGroupsUpdate422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessesGroupsUpdate>>,
        TError,
        {data: PatchApiBandaSystemAccessesGroupsUpdateBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessesGroupsUpdate>>,
    TError,
    {data: PatchApiBandaSystemAccessesGroupsUpdateBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessesGroupsUpdate>>,
        {data: PatchApiBandaSystemAccessesGroupsUpdateBody}
    > = (props) => {
        const {data} = props ?? {};

        return patchApiBandaSystemAccessesGroupsUpdate(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PatchApiBandaSystemAccessesGroupsUpdateMutationResult = NonNullable<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessesGroupsUpdate>>
>;
export type PatchApiBandaSystemAccessesGroupsUpdateMutationBody = PatchApiBandaSystemAccessesGroupsUpdateBody;
export type PatchApiBandaSystemAccessesGroupsUpdateMutationError =
    | PatchApiBandaSystemAccessesGroupsUpdate401
    | PatchApiBandaSystemAccessesGroupsUpdate403
    | PatchApiBandaSystemAccessesGroupsUpdate422;

/**
 * @summary Редактирование группы
 */
export const usePatchApiBandaSystemAccessesGroupsUpdate = <
    TError =
        | PatchApiBandaSystemAccessesGroupsUpdate401
        | PatchApiBandaSystemAccessesGroupsUpdate403
        | PatchApiBandaSystemAccessesGroupsUpdate422,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof patchApiBandaSystemAccessesGroupsUpdate>>,
        TError,
        {data: PatchApiBandaSystemAccessesGroupsUpdateBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof patchApiBandaSystemAccessesGroupsUpdate>>,
    TError,
    {data: PatchApiBandaSystemAccessesGroupsUpdateBody},
    TContext
> => {
    const mutationOptions = getPatchApiBandaSystemAccessesGroupsUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Справочник сотрудников
 */
export const getApiBandaSystemDirectoryEmployees = (signal?: AbortSignal) => {
    return axiosInstance<GetApiBandaSystemDirectoryEmployees200>({
        url: `/api/banda/system/directory/employees`,
        method: 'GET',
        signal
    });
};

export const getGetApiBandaSystemDirectoryEmployeesQueryKey = () => {
    return [`/api/banda/system/directory/employees`] as const;
};

export const getGetApiBandaSystemDirectoryEmployeesQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployees>>,
    TError = GetApiBandaSystemDirectoryEmployees401 | GetApiBandaSystemDirectoryEmployees403
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployees>>, TError, TData>>;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemDirectoryEmployeesQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployees>>> = ({signal}) =>
        getApiBandaSystemDirectoryEmployees(signal);

    return {
        queryKey,
        queryFn,
        ...queryOptions
    } as UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployees>>, TError, TData> & {
        queryKey: QueryKey;
    };
};

export type GetApiBandaSystemDirectoryEmployeesQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployees>>
>;
export type GetApiBandaSystemDirectoryEmployeesQueryError =
    | GetApiBandaSystemDirectoryEmployees401
    | GetApiBandaSystemDirectoryEmployees403;

/**
 * @summary Справочник сотрудников
 */
export const useGetApiBandaSystemDirectoryEmployees = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployees>>,
    TError = GetApiBandaSystemDirectoryEmployees401 | GetApiBandaSystemDirectoryEmployees403
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryEmployees>>, TError, TData>>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemDirectoryEmployeesQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Справочник подразделений
 */
export const getApiBandaSystemDirectoryDepartments = (signal?: AbortSignal) => {
    return axiosInstance<GetApiBandaSystemDirectoryDepartments200>({
        url: `/api/banda/system/directory/departments`,
        method: 'GET',
        signal
    });
};

export const getGetApiBandaSystemDirectoryDepartmentsQueryKey = () => {
    return [`/api/banda/system/directory/departments`] as const;
};

export const getGetApiBandaSystemDirectoryDepartmentsQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryDepartments>>,
    TError = GetApiBandaSystemDirectoryDepartments401 | GetApiBandaSystemDirectoryDepartments403
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryDepartments>>, TError, TData>>;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemDirectoryDepartmentsQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemDirectoryDepartments>>> = ({signal}) =>
        getApiBandaSystemDirectoryDepartments(signal);

    return {
        queryKey,
        queryFn,
        ...queryOptions
    } as UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryDepartments>>, TError, TData> & {
        queryKey: QueryKey;
    };
};

export type GetApiBandaSystemDirectoryDepartmentsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemDirectoryDepartments>>
>;
export type GetApiBandaSystemDirectoryDepartmentsQueryError =
    | GetApiBandaSystemDirectoryDepartments401
    | GetApiBandaSystemDirectoryDepartments403;

/**
 * @summary Справочник подразделений
 */
export const useGetApiBandaSystemDirectoryDepartments = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryDepartments>>,
    TError = GetApiBandaSystemDirectoryDepartments401 | GetApiBandaSystemDirectoryDepartments403
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryDepartments>>, TError, TData>>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemDirectoryDepartmentsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Справочник должностей
 */
export const getApiBandaSystemDirectoryPositions = (signal?: AbortSignal) => {
    return axiosInstance<GetApiBandaSystemDirectoryPositions200>({
        url: `/api/banda/system/directory/positions`,
        method: 'GET',
        signal
    });
};

export const getGetApiBandaSystemDirectoryPositionsQueryKey = () => {
    return [`/api/banda/system/directory/positions`] as const;
};

export const getGetApiBandaSystemDirectoryPositionsQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositions>>,
    TError = GetApiBandaSystemDirectoryPositions401 | GetApiBandaSystemDirectoryPositions403
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositions>>, TError, TData>>;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaSystemDirectoryPositionsQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositions>>> = ({signal}) =>
        getApiBandaSystemDirectoryPositions(signal);

    return {
        queryKey,
        queryFn,
        ...queryOptions
    } as UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositions>>, TError, TData> & {
        queryKey: QueryKey;
    };
};

export type GetApiBandaSystemDirectoryPositionsQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositions>>
>;
export type GetApiBandaSystemDirectoryPositionsQueryError =
    | GetApiBandaSystemDirectoryPositions401
    | GetApiBandaSystemDirectoryPositions403;

/**
 * @summary Справочник должностей
 */
export const useGetApiBandaSystemDirectoryPositions = <
    TData = Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositions>>,
    TError = GetApiBandaSystemDirectoryPositions401 | GetApiBandaSystemDirectoryPositions403
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaSystemDirectoryPositions>>, TError, TData>>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaSystemDirectoryPositionsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};
