import {Avatar, ButtonBase, MenuItem, Stack, Typography} from '@mui/material';
import {stringAvatar} from 'utils/functions';
import * as React from 'react';
import {UserLoginItem} from 'components/accessory/UserLoginItem';

export const UsersList = ({users, onClick}: {users: any[]; onClick: (token: string) => void}) => {
    return (
        <Stack gap={2}>
            {users.map((user) => (
                <Stack
                    key={user.id}
                    gap={1}
                    sx={{border: (theme) => '1px solid ' + theme.palette.divider, borderRadius: '6px', py: 1}}>
                    <MenuItem onClick={() => onClick?.(user.token)}>
                        <UserLoginItem user={user} />
                    </MenuItem>
                    {/*<UserItem {...{user, onClick}} />*/}
                    {!!user.service_account.length && (
                        <Stack gap={0.5} ml={2} mt={-1}>
                            <Typography variant={'body2'}>Сервисные аккаунты</Typography>
                            {user.service_account.map((service: any) => (
                                <MenuItem onClick={() => onClick?.(service.token)} key={service.id}>
                                    <UserLoginItem user={user} />
                                </MenuItem>
                            ))}
                        </Stack>
                    )}
                </Stack>
            ))}
        </Stack>
    );
};

const UserItem = ({user, onClick}: {user: any; onClick: (token: string) => void}) => {
    return (
        <ButtonBase
            onClick={() => onClick(user.token)}
            sx={{
                border: (theme) => '1px solid ' + theme.palette.divider,
                borderRadius: '8px',
                cursor: 'pointer',
                justifyContent: 'flex-start'
            }}>
            <Stack direction={'row'} alignItems={'center'} gap={2} padding={0.5}>
                <Avatar
                    {...stringAvatar(user?.first_name + ' ' + user?.last_name)}
                    sx={{
                        width: 40,
                        height: 40,
                        fontSize: '16px'
                    }}
                />
                <Stack alignItems={'flex-start'}>
                    <Typography variant={'h5'}>{user?.first_name + ' ' + user?.last_name}</Typography>
                    <Typography>{user?.email}</Typography>
                </Stack>
            </Stack>
        </ButtonBase>
    );
};
