/* eslint-disable camelcase */
import {Divider, Stack, Typography} from '@mui/material';
import {useForm} from 'react-hook-form';
import {Form} from 'components/form/Form';
import {useEffect} from 'react';
import {TablePerPage} from 'components/layouts/MainLayout/components/TableLayout';
import {useQsParams} from 'utils/hooks/useQsParams';

export const TableHeader = () => {
    const form = useForm();
    const {reset} = form;
    const [{per_page}] = useQsParams();
    useEffect(() => {
        reset({per_page});
    });
    return (
        <Form form={form}>
            <Stack gap={2}>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    gap={2}>
                    <Typography variant={'h5'}>Список прав</Typography>
                </Stack>
                <Divider sx={{mx: -3}} />
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    gap={2}>
                    <TablePerPage />
                </Stack>
            </Stack>
        </Form>
    );
};
