import {
    Box,
    Collapse,
    createSvgIcon,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import * as React from 'react';
import {ReactNode, useEffect, useState} from 'react';
import {MENU_ITEMS} from 'utils/constants/menu';
import {Link} from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {CircleIcon} from 'assets/icons';
import {svgColorMixin} from 'utils/mixins';

export const LeftPanelList = ({open}: {open: boolean}) => {
    return (
        <>
            {MENU_ITEMS.map((array) => (
                <>
                    <Stack sx={{px: '20px', pt: 2, height: 32, justifyContent: 'center'}}>
                        <Typography variant={'h5'} color={'grey.400'}>
                            {open ? array.title : <Divider />}
                        </Typography>
                    </Stack>

                    <List>
                        {array.links.map((item) => (
                            <MenuListItem item={item} key={item.title} openDrawer={open} />
                        ))}
                    </List>
                </>
            ))}
        </>
    );
};

const MenuListItem = ({item, openDrawer}: {item: any; openDrawer: boolean}) => {
    const [collapseOpen, setCollapseOpen] = useState(false);
    const isVisibleCollapse = item.children?.length && openDrawer;
    const childrenEqualityPathname = item.children?.find((child: any) => location.pathname.includes(child.href));
    useEffect(() => {
        if (childrenEqualityPathname) {
            setCollapseOpen(true);
        }
    }, []);
    const isSelected = location.pathname.includes(item.href);
    const Icon = createSvgIcon(item?.icon, 'item');
    return (
        <>
            <LinkOrButtonList
                href={item.href}
                onClick={(e: MouseEvent) => {
                    e.stopPropagation();
                    setCollapseOpen(!collapseOpen);
                }}>
                <ListItem disablePadding sx={{display: 'block'}}>
                    <ListItemButton
                        disableGutters
                        selected={isSelected}
                        sx={{
                            minHeight: 38,
                            justifyContent: openDrawer ? 'initial' : 'space-between',
                            mx: 1.5,
                            my: 0.5,
                            borderRadius: '6px',
                            backgroundColor: (theme) =>
                                collapseOpen || childrenEqualityPathname
                                    ? theme.palette.action.selected + ' !important'
                                    : undefined,
                            px: 1.5
                        }}>
                        <ListItemIcon
                            sx={(theme) => ({
                                height: 22,
                                minWidth: 0,
                                mr: openDrawer ? 1 : 'auto',
                                justifyContent: 'center',
                                alignItems: 'center',
                                ...svgColorMixin('currentColor')
                            })}>
                            {'icon' in item ? <Icon /> : <CircleIcon />}
                        </ListItemIcon>

                        <ListItemText
                            primary={item.title}
                            sx={{
                                my: 0,
                                opacity: openDrawer ? 1 : 0,
                                textWrap: 'wrap',
                                minWidth: '143px',
                                '& .MuiTypography-root': {
                                    lineHeight: '15px !important'
                                }
                            }}
                        />
                        {isVisibleCollapse && <> {collapseOpen ? <ExpandLess /> : <ExpandMore />}</>}
                    </ListItemButton>
                </ListItem>
            </LinkOrButtonList>
            {isVisibleCollapse && (
                <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        {item.children.map((children: any) => (
                            <MenuListItem item={children} key={children.title} openDrawer={openDrawer} />
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
};

const LinkOrButtonList = ({
    href,
    children,
    onClick
}: {
    href?: string;
    children: ReactNode;
    onClick?: (e: any) => void;
}) => {
    return <>{href ? <Link to={href}>{children}</Link> : <Box onClick={onClick}>{children}</Box>}</>;
};
