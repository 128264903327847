import React, {ReactNode} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Stack,
    SxProps,
    Theme
} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material';
import {iconsShadow} from 'utils/mixins';

export const CustomModal = (
    props: Omit<DialogProps, 'onClose'> & {
        hiddenCloseIcon?: boolean;
        customActions?: ReactNode;
        onClose?: () => void;
        sx?: SxProps<Theme>;
        modalTitle?: string | ReactNode;
        sxDialogTitle?: SxProps<Theme>;
        sxDialogContent?: SxProps<Theme>;
    }
) => {
    const {
        open,
        onClose,
        children,
        customActions,
        hiddenCloseIcon,
        sx,
        modalTitle,
        sxDialogTitle,
        sxDialogContent,
        ...rest
    } = props;
    return (
        <Dialog
            open={open}
            fullWidth
            onClose={onClose}
            PaperProps={{elevation: 8, sx: {overflow: 'visible', backgroundImage: 'none'}}}
            maxWidth={'md'}
            sx={{
                p: 1,
                '& .MuiDialog-paper': {margin: {xs: 0, md: 2}},
                ...sx
            }}
            transitionDuration={{exit: 0, enter: 400, appear: 400}}
            {...rest}>
            {!hiddenCloseIcon && (
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        borderRadius: '4px',
                        boxShadow: (theme) => ({
                            boxShadow: iconsShadow[theme.palette.mode]
                        }),
                        background: (theme) => theme.palette.background.paper + '!important',
                        width: 30,
                        height: 30,
                        position: 'absolute',
                        right: -10,
                        top: -10,
                        zIndex: 1000,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon sx={{opacity: 0.35, width: 18, height: 18}} />
                </IconButton>
            )}
            <Stack sx={{overflow: 'auto'}}>
                <DialogTitle id='alert-dialog-title' typography={'h5'} sx={{py: 3, ...sxDialogTitle}}>
                    {modalTitle}
                </DialogTitle>

                <DialogContent sx={{...sxDialogContent}}>{children}</DialogContent>
                {customActions && <DialogActions sx={{p: 0}}>{customActions}</DialogActions>}
            </Stack>
        </Dialog>
    );
};
