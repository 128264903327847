import {Paper} from 'components/accessory/Paper';
import {paperPaddingMixin} from 'utils/mixins';
import {useRef, useState} from 'react';
import {
    AdmonitionDirectiveDescriptor,
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    ChangeCodeMirrorLanguage,
    codeBlockPlugin,
    codeMirrorPlugin,
    CodeToggle,
    ConditionalContents,
    CreateLink,
    directivesPlugin,
    frontmatterPlugin,
    headingsPlugin,
    imagePlugin,
    InsertAdmonition,
    InsertCodeBlock,
    InsertFrontmatter,
    InsertImage,
    InsertSandpack,
    InsertTable,
    InsertThematicBreak,
    linkPlugin,
    listsPlugin,
    ListsToggle,
    markdownShortcutPlugin,
    MDXEditor,
    MDXEditorMethods,
    quotePlugin,
    SandpackConfig,
    sandpackPlugin,
    ShowSandpackInfo,
    tablePlugin,
    thematicBreakPlugin,
    toolbarPlugin,
    UndoRedo
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';

export const News = () => {
    const ref = useRef<MDXEditorMethods>(null);
    const [value, setValue] = useState<string>(
        ':::note\n' +
            'foo\n' +
            ':::\n' +
            '\n' +
            ':::tip\n' +
            'Some **content** with *Markdown* syntax. Check [this component](https://virtuoso.dev/).\n' +
            ':::\n' +
            '\n' +
            ':::info\n' +
            'Some **content** with *Markdown* syntax.\n' +
            ':::\n' +
            '\n' +
            ':::caution\n' +
            'Some **content** with *Markdown* syntax.\n' +
            ':::\n' +
            '\n' +
            ':::danger\n' +
            'Some **content** with *Markdown* syntax.\n' +
            'slug: hello-world\n' +
            '-----------------\n' +
            ':::\n' +
            '\n' +
            '\n' +
            '\n' +
            '|     | xcx | x |   |\n' +
            '| --- | --- | - | - |\n' +
            '|     |     |   |   |\n' +
            '| zxc |     |   | c |\n' +
            '|     |     |   |   |\n' +
            '\n' +
            '\n' +
            '\n' +
            '```jsx live react\n' +
            'export default function App() {\n' +
            '  return (\n' +
            "    <div className='App'>\n" +
            '      <h1>Hello CodeSandbox</h1>\n' +
            '      <h2>Start editing to see some magic happen!</h2>\n' +
            '    </div>\n' +
            '  );\n' +
            '}\n' +
            '```\n' +
            '---\n' +
            'slug: hello-world\n' +
            'title: Blogging Like a Hacker\n' +
            '---\n' +
            '\n' +
            'this is a cool markdown'
    );

    const defaultSnippetContent = `
export default function App() {
  return (
    <div className='App'>
      <h1>Hello CodeSandbox</h1>
      <h2>Start editing to see some magic happen!</h2>
    </div>
  );
}
`.trim();

    const simpleSandpackConfig: SandpackConfig = {
        defaultPreset: 'react',
        presets: [
            {
                label: 'React',
                name: 'react',
                meta: 'live react',
                sandpackTemplate: 'react',
                sandpackTheme: 'light',
                snippetFileName: '/App.js',
                snippetLanguage: 'jsx',
                initialSnippetContent: defaultSnippetContent
            }
        ]
    };
    return (
        <Paper
            sx={{
                ...paperPaddingMixin,
                '& .wmde-markdown, .w-md-editor-preview': {
                    color: (theme) => theme.palette.text.primary,
                    background: (theme) => theme.palette.background.paper
                },
                '& .wmde-markdown pre': {
                    color: (theme) => theme.palette.primary.contrastText
                }
            }}>
            <MDXEditor
                ref={ref}
                markdown={value}
                onChange={setValue}
                plugins={[
                    // diffSourcePlugin(),
                    frontmatterPlugin(),
                    imagePlugin(),
                    sandpackPlugin(),
                    tablePlugin(),
                    thematicBreakPlugin(),
                    headingsPlugin(),
                    listsPlugin(),
                    linkPlugin(),
                    quotePlugin(),
                    markdownShortcutPlugin(),
                    directivesPlugin({directiveDescriptors: [AdmonitionDirectiveDescriptor]}),
                    codeBlockPlugin({defaultCodeBlockLanguage: 'js'}),
                    sandpackPlugin({sandpackConfig: simpleSandpackConfig}),
                    codeMirrorPlugin({codeBlockLanguages: {js: 'JavaScript', css: 'CSS'}}),
                    toolbarPlugin({
                        toolbarContents: () => (
                            <>
                                <UndoRedo />
                                <BlockTypeSelect />
                                <BoldItalicUnderlineToggles />
                                {/*<ChangeAdmonitionType />*/}
                                {/*<ChangeCodeMirrorLanguage />*/}
                                <CodeToggle />
                                <CreateLink />
                                {/*<DiffSourceToggleWrapper />*/}
                                <InsertAdmonition />
                                {/*<InsertCodeBlock />*/}
                                <InsertFrontmatter />
                                <InsertImage />
                                {/*<InsertSandpack />*/}
                                <InsertTable />
                                <InsertThematicBreak />
                                <ListsToggle />
                                <ConditionalContents
                                    options={[
                                        {
                                            when: (editor) => editor?.editorType === 'codeblock',
                                            contents: () => <ChangeCodeMirrorLanguage />
                                        },
                                        {
                                            when: (editor) => editor?.editorType === 'sandpack',
                                            contents: () => <ShowSandpackInfo />
                                        },
                                        {
                                            fallback: () => (
                                                <>
                                                    <InsertCodeBlock />
                                                    <InsertSandpack />
                                                </>
                                            )
                                        }
                                    ]}
                                />
                                {/*<ShowSandpackInfo />*/}
                            </>
                        )
                    })
                ]}
            />
        </Paper>
    );
};
