/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BANDA API
 * OpenAPI spec version: 1.0.0
 */
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult
} from '@tanstack/react-query';
import {useMutation, useQuery} from '@tanstack/react-query';
import type {
    GetApiV1AuthenticationUser200,
    GetApiV1AuthenticationUser401,
    PostApiV1AuthenticationLoginOrEmail200,
    PostApiV1AuthenticationLoginOrEmail401,
    PostApiV1AuthenticationLoginOrEmail422,
    PostApiV1AuthenticationLoginOrEmail429,
    PostApiV1AuthenticationLoginOrEmailBody,
    PostApiV1AuthenticationLogout200,
    PostApiV1AuthenticationLogout401
} from './bandaApi.schemas';
import {axiosInstance} from './mutator/axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Метод проверки авторизации пользователя
 */
export const getApiV1AuthenticationUser = (signal?: AbortSignal) => {
    return axiosInstance<GetApiV1AuthenticationUser200>({url: `/api/v1/authentication/user`, method: 'GET', signal});
};

export const getGetApiV1AuthenticationUserQueryKey = () => {
    return [`/api/v1/authentication/user`] as const;
};

export const getGetApiV1AuthenticationUserQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiV1AuthenticationUser>>,
    TError = GetApiV1AuthenticationUser401
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AuthenticationUser>>, TError, TData>>;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiV1AuthenticationUserQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AuthenticationUser>>> = ({signal}) =>
        getApiV1AuthenticationUser(signal);

    return {
        queryKey,
        queryFn,
        ...queryOptions
    } as UseQueryOptions<Awaited<ReturnType<typeof getApiV1AuthenticationUser>>, TError, TData> & {queryKey: QueryKey};
};

export type GetApiV1AuthenticationUserQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AuthenticationUser>>>;
export type GetApiV1AuthenticationUserQueryError = GetApiV1AuthenticationUser401;

/**
 * @summary Метод проверки авторизации пользователя
 */
export const useGetApiV1AuthenticationUser = <
    TData = Awaited<ReturnType<typeof getApiV1AuthenticationUser>>,
    TError = GetApiV1AuthenticationUser401
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1AuthenticationUser>>, TError, TData>>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiV1AuthenticationUserQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Рейтлимит 5 попыток входа в минуту - блокировка на 60 секунд
 * @summary Метод авторизации пользователя
 */
export const postApiV1AuthenticationLoginOrEmail = (
    postApiV1AuthenticationLoginOrEmailBody: PostApiV1AuthenticationLoginOrEmailBody
) => {
    return axiosInstance<PostApiV1AuthenticationLoginOrEmail200>({
        url: `/api/v1/authentication/loginOrEmail`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: postApiV1AuthenticationLoginOrEmailBody
    });
};

export const getPostApiV1AuthenticationLoginOrEmailMutationOptions = <
    TError =
        | PostApiV1AuthenticationLoginOrEmail401
        | PostApiV1AuthenticationLoginOrEmail422
        | PostApiV1AuthenticationLoginOrEmail429,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiV1AuthenticationLoginOrEmail>>,
        TError,
        {data: PostApiV1AuthenticationLoginOrEmailBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiV1AuthenticationLoginOrEmail>>,
    TError,
    {data: PostApiV1AuthenticationLoginOrEmailBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiV1AuthenticationLoginOrEmail>>,
        {data: PostApiV1AuthenticationLoginOrEmailBody}
    > = (props) => {
        const {data} = props ?? {};

        return postApiV1AuthenticationLoginOrEmail(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiV1AuthenticationLoginOrEmailMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiV1AuthenticationLoginOrEmail>>
>;
export type PostApiV1AuthenticationLoginOrEmailMutationBody = PostApiV1AuthenticationLoginOrEmailBody;
export type PostApiV1AuthenticationLoginOrEmailMutationError =
    | PostApiV1AuthenticationLoginOrEmail401
    | PostApiV1AuthenticationLoginOrEmail422
    | PostApiV1AuthenticationLoginOrEmail429;

/**
 * @summary Метод авторизации пользователя
 */
export const usePostApiV1AuthenticationLoginOrEmail = <
    TError =
        | PostApiV1AuthenticationLoginOrEmail401
        | PostApiV1AuthenticationLoginOrEmail422
        | PostApiV1AuthenticationLoginOrEmail429,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiV1AuthenticationLoginOrEmail>>,
        TError,
        {data: PostApiV1AuthenticationLoginOrEmailBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiV1AuthenticationLoginOrEmail>>,
    TError,
    {data: PostApiV1AuthenticationLoginOrEmailBody},
    TContext
> => {
    const mutationOptions = getPostApiV1AuthenticationLoginOrEmailMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Метод удаления токена (выход)
 */
export const postApiV1AuthenticationLogout = () => {
    return axiosInstance<PostApiV1AuthenticationLogout200>({url: `/api/v1/authentication/logout`, method: 'POST'});
};

export const getPostApiV1AuthenticationLogoutMutationOptions = <
    TError = PostApiV1AuthenticationLogout401,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiV1AuthenticationLogout>>, TError, void, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AuthenticationLogout>>, TError, void, TContext> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AuthenticationLogout>>, void> = () => {
        return postApiV1AuthenticationLogout();
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiV1AuthenticationLogoutMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiV1AuthenticationLogout>>
>;

export type PostApiV1AuthenticationLogoutMutationError = PostApiV1AuthenticationLogout401;

/**
 * @summary Метод удаления токена (выход)
 */
export const usePostApiV1AuthenticationLogout = <
    TError = PostApiV1AuthenticationLogout401,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiV1AuthenticationLogout>>, TError, void, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof postApiV1AuthenticationLogout>>, TError, void, TContext> => {
    const mutationOptions = getPostApiV1AuthenticationLogoutMutationOptions(options);

    return useMutation(mutationOptions);
};
