import {Stack, SxProps, Typography} from '@mui/material';
import React, {PropsWithChildren} from 'react';
import {Theme} from '@mui/material/styles';

export const Labeled = ({
    children,
    label,
    labelSx,
    direction = 'column',
    fullWidth
}: PropsWithChildren<{
    label?: string;
    labelSx?: SxProps<Theme>;
    direction?: 'row' | 'column';
    fullWidth?: boolean;
}>) => {
    return (
        <Stack
            width={fullWidth ? '100%' : 'auto'}
            sx={labelSx}
            maxWidth={'100%'}
            direction={direction}
            position={'relative'}
            gap={direction === 'row' ? 1 : 0}
            alignItems={direction === 'row' ? 'center' : 'flex-start'}>
            {label && (
                <Typography variant={'body2'} sx={{hyphens: 'auto'}}>
                    {label || ''}
                </Typography>
            )}

            {children}
        </Stack>
    );
};
