import {
    alpha,
    Button,
    Pagination,
    PaginationRenderItemParams,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useQsParams} from 'utils/hooks/useQsParams';
import {Skeleton} from '@mui/lab';
import {Loader} from 'components/accessory/Loader';
import {LeftIcon, RightIcon} from 'assets/icons';
import {FormSelect} from 'components/form/FormSelect';

export const TableLayout = ({
    columns,
    rows,
    isLoading,
    filterRow,
    minWidth,
    pagination,
    negativeMargin
}: {
    columns?: any[];
    rows?: any[];
    isLoading?: boolean;
    filterRow?: boolean;
    minWidth?: number;
    pagination?: any;
    negativeMargin?: boolean;
}) => {
    const [prevLengthRows, setPrevLengthRows] = useState(0);
    const emptyArray = prevLengthRows ? new Array(prevLengthRows).fill(0) : [];
    useEffect(() => {
        if (rows?.length && rows.length !== prevLengthRows) {
            setPrevLengthRows(rows.length);
        }
    }, [rows?.length]);
    return (
        <Stack gap={2} sx={{mx: negativeMargin ? -3 : undefined}}>
            <TableContainer>
                <Table sx={{minWidth: minWidth || 650}} aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            {columns?.map((col, index) => (
                                <TableCell
                                    key={col?.name || index}
                                    align={col?.align as AlignType}
                                    variant={'head'}
                                    sx={col?.sx}>
                                    {col.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {filterRow && (
                            <TableRow>
                                {columns?.map((col, index) => (
                                    <TableCell
                                        key={col?.name || index}
                                        align={col?.align as AlignType}
                                        variant={'head'}
                                        sx={col?.sx}>
                                        {col.filter}
                                    </TableCell>
                                ))}
                            </TableRow>
                        )}
                        {!prevLengthRows && isLoading ? (
                            <></>
                        ) : (
                            <>
                                {rows?.length || !isLoading ? (
                                    <>
                                        {rows?.map((row) => (
                                            <Rows {...{columns, row}} key={row.id} />
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {emptyArray?.map((row, index) => (
                                            <Rows {...{columns, row}} isSkeleton key={index} />
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </TableBody>
                </Table>
                {!prevLengthRows && isLoading && <Loader />}
            </TableContainer>
            {!!pagination && (
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    mx={negativeMargin ? 3 : undefined}>
                    <PaginationInfo pagination={pagination} />
                    <PaginationTable total={pagination?.last_page} />
                </Stack>
            )}
        </Stack>
    );
};
const Rows = ({columns, row, isSkeleton}: any) => {
    return (
        <TableRow sx={{'&:hover': {backgroundColor: (theme) => theme.palette.action.hover}}}>
            {columns?.map((col: any, index: any) => (
                <TableCell key={col?.name || index} align={col?.align as AlignType}>
                    {isSkeleton ? (
                        <Typography
                            component={'div'}
                            sx={{
                                minHeight: '40px'
                            }}>
                            <Skeleton />
                        </Typography>
                    ) : (
                        col.value?.(row)
                    )}
                </TableCell>
            ))}
        </TableRow>
    );
};
export const PaginationInfo = ({pagination}: any) => {
    return (
        <Stack sx={{alignItems: 'center'}}>
            <Typography>
                Отображается от {pagination?.from} до {pagination?.to} из {pagination?.total} записей
            </Typography>
        </Stack>
    );
};
export const PaginationTable = ({total}: {total?: number}) => {
    const [totalPage, setTotalPage] = useState(total);
    useEffect(() => {
        if (total && total !== totalPage) setTotalPage(total);
    }, [total]);
    const [params, , setParamsSmart] = useQsParams();
    const page = Number(params.page || 1);
    const setNewPage = (item: PaginationRenderItemParams) => {
        const thisPage = page;
        setParamsSmart({
            page:
                item?.type === 'end-ellipsis'
                    ? thisPage + 5
                    : item?.type === 'start-ellipsis'
                    ? thisPage - 5
                    : item?.page || 1
        });
    };
    useEffect(() => {
        if (totalPage) {
            if (page <= 0 || !page) setParamsSmart({page: 1});
            if (page > totalPage) setParamsSmart({page: totalPage});
        }
    }, [page, totalPage]);
    return (
        <Stack>
            {totalPage && (
                <Pagination
                    count={totalPage}
                    page={page}
                    defaultPage={1}
                    boundaryCount={2}
                    size={'large'}
                    sx={{'& .MuiPagination-ul': {gap: 1}}}
                    renderItem={(item) => {
                        return <PaginationItem {...{item}} onClick={setNewPage} />;
                    }}
                />
            )}
        </Stack>
    );
};
const PaginationItem = ({
    item,
    onClick
}: {
    item: PaginationRenderItemParams;
    onClick: (page: PaginationRenderItemParams) => void;
}) => {
    const [hover, setHover] = useState(false);
    const textType = {
        next: () => <RightIcon />,
        previous: () => <LeftIcon />,
        'end-ellipsis': () => (hover ? <RightIcon /> : '...'),
        'start-ellipsis': () => (hover ? <LeftIcon /> : '...'),
        page: () => item.page
    };
    return (
        <Button
            variant={'text'}
            onClick={() => onClick(item)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            disabled={item.disabled}
            sx={{
                width: '38px',
                height: '38px',
                p: 0,
                minWidth: '38px',
                border: (theme) =>
                    '1px solid ' +
                    (item.selected
                        ? theme.palette.primary.main
                        : item.type === 'page'
                        ? theme.palette.divider
                        : 'transparent'),
                ' path': {stroke: (theme) => theme.palette.text.primary},
                color: (theme) => (item.selected ? 'white' : theme.palette.text.primary),
                background: (theme) => (item.selected ? theme.palette.primary.main : theme.palette.background.default),
                '&:hover': {
                    background: (theme) =>
                        item.selected ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.05)
                },
                '& path': {stroke: (theme) => (item.disabled ? theme.palette.divider : theme.palette.text.primary)},
                '&:hover path': {stroke: (theme) => theme.palette.text.primary}
            }}>
            {textType[item.type as keyof typeof textType]?.() || ''}
        </Button>
    );
};

export const TablePerPage = () => {
    return (
        <Stack gap={1} direction={'row'} alignItems={'center'}>
            Показывать
            <FormSelect
                name={'per_page'}
                options={PER_PAGE_OPTIONS}
                optionValue={'value'}
                isSearch
                fullWidth
                placeholder={'25'}
                sx={{width: {md: '88px', xs: '100%'}}}
            />
            записей
        </Stack>
    );
};

const PER_PAGE_OPTIONS = [
    {
        id: 1,
        name: '10',
        value: '10'
    },
    {
        id: 2,
        name: '25',
        value: '25'
    },
    {
        id: 3,
        name: '50',
        value: '50'
    },
    {
        id: 4,
        name: '100',
        value: '100'
    }
];

type AlignType = 'left' | 'right' | 'center' | 'inherit' | 'justify' | undefined;
