import {Avatar, Stack, Typography} from '@mui/material';
import {stringAvatar} from 'utils/functions';
import * as React from 'react';

export const UserLoginItem = ({user}: {user: any}) => {
    return (
        <Stack direction={'row'} alignItems={'center'} gap={1}>
            <Avatar
                {...stringAvatar(user?.first_name + ' ' + user?.last_name)}
                sx={{
                    width: 32,
                    height: 32,
                    fontSize: '16px'
                }}
            />
            <Stack alignItems={'flex-start'}>
                <Typography variant={'h6'}>{user?.first_name + ' ' + user?.last_name}</Typography>
                <Typography variant={'body2'}>{user?.email}</Typography>
            </Stack>
        </Stack>
    );
};
