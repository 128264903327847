import {alpha, IconButton, Stack, Toolbar, useMediaQuery} from '@mui/material';
import * as React from 'react';
import {styled, Theme} from '@mui/material/styles';
import {drawerWidth} from 'utils/constants';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import {useAuthContext} from 'context/authContext';
import {shadow, transitionMixin} from 'utils/mixins';
import {Notifications} from 'components/layouts/MainLayout/components/AppBar/components/Notifications';
import {SwitchTheme} from 'components/layouts/MainLayout/components/AppBar/components/SwitchTheme';
import {UserMenu} from 'components/layouts/MainLayout/components/AppBar/components/UserMenu';
import {BurgerIcon} from 'assets/icons';

export const AppBarTop = ({
    open,
    setOpen,
    blockOpen
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    blockOpen: boolean;
}) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const {me} = useAuthContext();

    return (
        <AppBar
            position='sticky'
            open={open}
            blockopen={blockOpen ? 'open' : undefined}
            sx={(theme) => ({
                top: {xs: 0, md: 16},
                backgroundColor: 'transparent',
                backgroundImage: 'none',
                ...transitionMixin(theme),
                boxShadow: 'none',
                marginRight: 0,
                marginLeft: {
                    xs: 0,
                    md: blockOpen ? `calc(${drawerWidth}px + 1px)` : `calc(${theme.spacing(9)})`
                }
            })}>
            <Toolbar
                sx={(theme) => ({
                    boxShadow: shadow,
                    mx: {xs: 2, md: 3},
                    borderRadius: '6px',
                    zIndex: 2,
                    backgroundColor: alpha(theme.palette.background.paper, 0.88),
                    minHeight: 56
                    // ...theme.mixins.toolbar
                })}>
                {!isMd && (
                    <IconButton onClick={() => setOpen(true)}>
                        <BurgerIcon />
                    </IconButton>
                )}

                {me && (
                    <Stack direction={'row'} sx={{ml: 'auto'}} alignItems={'center'}>
                        <SwitchTheme isOpenLeftMenu={open} />
                        <Notifications />
                        <UserMenu isOpenLeftMenu={open} />
                    </Stack>
                )}
            </Toolbar>
        </AppBar>
    );
};
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps & {blockopen?: string}>(({theme, open, blockopen}) => ({
    zIndex: theme.zIndex.drawer - 1,
    marginTop: 8,
    marginRight: 8,
    width: 'auto',
    [theme.breakpoints.up('md')]: {
        marginTop: 16,
        width: blockopen ? `calc(100% - ${drawerWidth}px - 2px)` : `calc(100% - ${theme.spacing(9)})`
    },
    // backgroundColor: 'rgba(255, 255, 255, 0.95)',
    borderRadius: '6px',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}
