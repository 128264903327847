import {CircularProgress, Stack} from '@mui/material';
import React from 'react';

export const Loader = () => {
    return (
        <Stack sx={{height: '100px', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
            <CircularProgress color='secondary' />
        </Stack>
    );
};
