import {Link, Stack, useMediaQuery} from '@mui/material';
import {drawerWidth} from 'utils/constants';
import {FOOTER_LINKS} from 'utils/constants/menu';
import {transitionMixin} from 'utils/mixins';
import {Theme} from '@mui/material/styles';

export const Footer = ({blockOpen}: {blockOpen?: boolean}) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    return (
        <Stack
            component={'footer'}
            sx={(theme) => ({
                mb: {xs: 1, md: 2},
                marginTop: 'auto !important',
                paddingTop: {xs: 1, md: 2},
                ...transitionMixin(theme),
                marginLeft: (theme) => ({
                    md: blockOpen ? `calc(${drawerWidth}px + 1px)` : `calc(${theme.spacing(9)})`
                })
            })}>
            <Stack direction={'row'} sx={{justifyContent: {xs: 'center', md: 'space-between'}, px: {xs: 2, md: 3}}}>
                <Stack>© 2024, Сделано с ❤️ ваш Тех85</Stack>
                {isMd && (
                    <Stack direction={'row'} gap={2}>
                        {FOOTER_LINKS.map((link) => (
                            <Link key={link.title} href={link.href} color={'text.secondary'} underline='none'>
                                {link.title}
                            </Link>
                        ))}
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};
