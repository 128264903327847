import {Stack, Typography} from '@mui/material';
import * as React from 'react';
import {UserLoginItem} from 'components/accessory/UserLoginItem';

export const UsersList = ({users, onClick}: {users: any[]; onClick: (token: string) => void}) => {
    return (
        <Stack gap={1}>
            {users.map((user) => (
                <React.Fragment key={user.id}>
                    <UserLoginItem user={user} onClick={() => onClick?.(user.token)} />
                    {!!user.service_account.length && (
                        <Stack gap={0.5} ml={2} mt={-1}>
                            <Typography variant={'body2'}>Сервисные аккаунты</Typography>
                            {user?.service_account?.map((service: any) => (
                                <UserLoginItem user={user} onClick={() => onClick?.(service.token)} key={service.id} />
                            ))}
                        </Stack>
                    )}
                </React.Fragment>
            ))}
        </Stack>
    );
};
