/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BANDA API
 * OpenAPI spec version: 1.0.0
 */
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult
} from '@tanstack/react-query';
import {useMutation, useQuery} from '@tanstack/react-query';
import type {
    GetApiBandaAuthenticationUser401,
    PostApiBandaAuthenticationLoginOrEmail200,
    PostApiBandaAuthenticationLoginOrEmail401,
    PostApiBandaAuthenticationLoginOrEmail422,
    PostApiBandaAuthenticationLoginOrEmail429,
    PostApiBandaAuthenticationLoginOrEmailBody,
    PostApiBandaAuthenticationLogout200,
    PostApiBandaAuthenticationLogout401
} from './bandaApi.schemas';
import {axiosInstance} from './mutator/axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Метод проверки авторизации пользователя
 */
export const getApiBandaAuthenticationUser = (signal?: AbortSignal) => {
    return axiosInstance<unknown>({url: `/api/banda/authentication/user`, method: 'GET', signal});
};

export const getGetApiBandaAuthenticationUserQueryKey = () => {
    return [`/api/banda/authentication/user`] as const;
};

export const getGetApiBandaAuthenticationUserQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaAuthenticationUser>>,
    TError = GetApiBandaAuthenticationUser401
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaAuthenticationUser>>, TError, TData>>;
}) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaAuthenticationUserQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaAuthenticationUser>>> = ({signal}) =>
        getApiBandaAuthenticationUser(signal);

    return {
        queryKey,
        queryFn,
        ...queryOptions
    } as UseQueryOptions<Awaited<ReturnType<typeof getApiBandaAuthenticationUser>>, TError, TData> & {
        queryKey: QueryKey;
    };
};

export type GetApiBandaAuthenticationUserQueryResult = NonNullable<
    Awaited<ReturnType<typeof getApiBandaAuthenticationUser>>
>;
export type GetApiBandaAuthenticationUserQueryError = GetApiBandaAuthenticationUser401;

/**
 * @summary Метод проверки авторизации пользователя
 */
export const useGetApiBandaAuthenticationUser = <
    TData = Awaited<ReturnType<typeof getApiBandaAuthenticationUser>>,
    TError = GetApiBandaAuthenticationUser401
>(options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaAuthenticationUser>>, TError, TData>>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaAuthenticationUserQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * Рейтлимит 5 попыток входа в минуту - блокировка на 60 секунд
 * @summary Метод авторизации пользователя
 */
export const postApiBandaAuthenticationLoginOrEmail = (
    postApiBandaAuthenticationLoginOrEmailBody: PostApiBandaAuthenticationLoginOrEmailBody
) => {
    return axiosInstance<PostApiBandaAuthenticationLoginOrEmail200>({
        url: `/api/banda/authentication/loginOrEmail`,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        data: postApiBandaAuthenticationLoginOrEmailBody
    });
};

export const getPostApiBandaAuthenticationLoginOrEmailMutationOptions = <
    TError =
        | PostApiBandaAuthenticationLoginOrEmail401
        | PostApiBandaAuthenticationLoginOrEmail422
        | PostApiBandaAuthenticationLoginOrEmail429,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaAuthenticationLoginOrEmail>>,
        TError,
        {data: PostApiBandaAuthenticationLoginOrEmailBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiBandaAuthenticationLoginOrEmail>>,
    TError,
    {data: PostApiBandaAuthenticationLoginOrEmailBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiBandaAuthenticationLoginOrEmail>>,
        {data: PostApiBandaAuthenticationLoginOrEmailBody}
    > = (props) => {
        const {data} = props ?? {};

        return postApiBandaAuthenticationLoginOrEmail(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiBandaAuthenticationLoginOrEmailMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiBandaAuthenticationLoginOrEmail>>
>;
export type PostApiBandaAuthenticationLoginOrEmailMutationBody = PostApiBandaAuthenticationLoginOrEmailBody;
export type PostApiBandaAuthenticationLoginOrEmailMutationError =
    | PostApiBandaAuthenticationLoginOrEmail401
    | PostApiBandaAuthenticationLoginOrEmail422
    | PostApiBandaAuthenticationLoginOrEmail429;

/**
 * @summary Метод авторизации пользователя
 */
export const usePostApiBandaAuthenticationLoginOrEmail = <
    TError =
        | PostApiBandaAuthenticationLoginOrEmail401
        | PostApiBandaAuthenticationLoginOrEmail422
        | PostApiBandaAuthenticationLoginOrEmail429,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaAuthenticationLoginOrEmail>>,
        TError,
        {data: PostApiBandaAuthenticationLoginOrEmailBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiBandaAuthenticationLoginOrEmail>>,
    TError,
    {data: PostApiBandaAuthenticationLoginOrEmailBody},
    TContext
> => {
    const mutationOptions = getPostApiBandaAuthenticationLoginOrEmailMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Метод удаления токена (выход)
 */
export const postApiBandaAuthenticationLogout = () => {
    return axiosInstance<PostApiBandaAuthenticationLogout200>({
        url: `/api/banda/authentication/logout`,
        method: 'POST'
    });
};

export const getPostApiBandaAuthenticationLogoutMutationOptions = <
    TError = PostApiBandaAuthenticationLogout401,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiBandaAuthenticationLogout>>, TError, void, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof postApiBandaAuthenticationLogout>>, TError, void, TContext> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiBandaAuthenticationLogout>>, void> = () => {
        return postApiBandaAuthenticationLogout();
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiBandaAuthenticationLogoutMutationResult = NonNullable<
    Awaited<ReturnType<typeof postApiBandaAuthenticationLogout>>
>;

export type PostApiBandaAuthenticationLogoutMutationError = PostApiBandaAuthenticationLogout401;

/**
 * @summary Метод удаления токена (выход)
 */
export const usePostApiBandaAuthenticationLogout = <
    TError = PostApiBandaAuthenticationLogout401,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiBandaAuthenticationLogout>>, TError, void, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof postApiBandaAuthenticationLogout>>, TError, void, TContext> => {
    const mutationOptions = getPostApiBandaAuthenticationLogoutMutationOptions(options);

    return useMutation(mutationOptions);
};
