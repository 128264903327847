import merge from 'lodash/merge';
import {createTheme} from '@mui/material';
import {whiteTheme} from './whiteTheme';
import {blackTheme} from './blackTheme';
import {baseTheme} from './baseTheme';

export const MODE = {
    LIGHT: 'light',
    DARK: 'dark',
    SYSTEM: 'system'
};

const themesOptions = {
    [MODE.LIGHT]: whiteTheme,
    [MODE.DARK]: blackTheme,
    [MODE.SYSTEM]: window.matchMedia('(prefers-color-scheme: DARK)').matches ? blackTheme : whiteTheme
};
const createAppTheme = (theme: keyof typeof themesOptions) => {
    let themeOptions = themesOptions[theme];
    if (!themeOptions) {
        console.warn(new Error(`The theme ${theme} is not valid`));
        themeOptions = themesOptions[MODE.LIGHT];
    }
    return createTheme(merge({}, baseTheme, themeOptions));
};
export default createAppTheme;
