import {ROUTES} from 'utils/constants/index';
import {
    AuditsIcon,
    BeachIcon,
    BirthdaysIcon,
    CheckboxIcon,
    ChecklistIcon,
    CloudIcon,
    ContactsIcon,
    DocsIcon,
    GiftIcon,
    HelpIcon,
    IdeaIcon,
    ListDetailsIcon,
    LogsIcon,
    NewsIcon,
    SitemapIcon,
    SubstitutionIcon,
    TableIcon,
    TestsIcon,
    UserShieldIcon,
    UsersIcon,
    WikiIcon
} from 'assets/icons';

export const MENU_ITEMS = [
    {
        links: [
            {
                title: 'Новости',
                href: ROUTES.NEWS,
                icon: <NewsIcon />
            },
            {
                title: 'Табель',
                href: ROUTES.TABLE,
                icon: <TableIcon />
            },
            {
                title: 'Подмены',
                href: ROUTES.SUBSTITUTIONS,
                icon: <SubstitutionIcon />
            },
            {
                title: 'Отпуска',
                href: ROUTES.VACATIONS,
                icon: <BeachIcon />
            },
            {
                title: 'Контакты',
                href: ROUTES.CONTACTS,
                icon: <ContactsIcon />
            },
            {
                title: 'Дни рождения',
                href: ROUTES.BIRTHDAYS,
                icon: <BirthdaysIcon />
            },
            {
                title: 'Оргструктура',
                href: ROUTES.STRUCTURE,
                icon: <SitemapIcon />
            },
            {
                title: 'База знаний',
                href: ROUTES.WIKI,
                icon: <WikiIcon />
            },
            {
                title: 'Заявки',
                href: ROUTES.REQUESTS,
                icon: <ListDetailsIcon />
            },
            {
                title: 'Опросы',
                href: ROUTES.SURVEYS,
                icon: <CheckboxIcon />
            },
            {
                title: 'Тесты',
                href: ROUTES.TESTS,
                icon: <TestsIcon />
            },
            {
                title: 'Чек-листы',
                href: ROUTES.CHECK_LISTS,
                icon: <ChecklistIcon />
            },
            {
                title: 'Аудиты',
                href: ROUTES.AUDITS,
                icon: <AuditsIcon />
            },
            {
                title: 'Хранилище',
                href: ROUTES.STORAGE,
                icon: <CloudIcon />
            },
            {
                title: 'Спасибо',
                href: ROUTES.THANK,
                icon: <GiftIcon />
            }
        ]
    },
    {
        title: 'Система',
        links: [
            {
                title: 'Сотруднки',
                children: [
                    {
                        title: 'Аккаунты',
                        href: ROUTES.USER_ACCOUNTS
                    },
                    {
                        title: 'Группы пользователей',
                        href: ROUTES.USER_GROUPS
                    },
                    {
                        title: 'Сервисные аккаунты',
                        href: ROUTES.USER_SERVICE_ACCOUNTS
                    },
                    {
                        title: 'Рабочие профили',
                        href: ROUTES.USER_PROFILES
                    },
                    {
                        title: 'Штатные расписания',
                        href: ROUTES.USER_SCHEDULERS
                    },
                    {
                        title: 'Должности',
                        href: ROUTES.USER_POSITIONS
                    },
                    {
                        title: 'Подразделения',
                        href: ROUTES.USER_DIVISIONS
                    }
                ],
                icon: <UsersIcon />
            },
            {
                title: 'Права и роли',
                children: [
                    {
                        title: 'Список прав',
                        href: ROUTES.PERMISSIONS_LIST
                    },
                    {
                        title: 'Список групп',
                        href: ROUTES.PERMISSIONS_GROUPS
                    },
                    {
                        title: 'Список ролей',
                        href: ROUTES.PERMISSIONS_ROLES
                    }
                ],
                icon: <UserShieldIcon />
            },
            {
                title: 'Логи',
                href: ROUTES.LOGS,
                icon: <LogsIcon />
            }
        ]
    },
    {
        title: 'Помощь',
        links: [
            {
                title: 'Помощь',
                href: ROUTES.HELP,
                icon: <HelpIcon />
            },
            {
                title: 'Документация',
                href: ROUTES.DOCUMENTATION,
                icon: <DocsIcon />
            },
            {
                title: 'Идея',
                href: ROUTES.IDEA,
                icon: <IdeaIcon />
            }
        ]
    }
];

export const FOOTER_LINKS = [
    {title: 'Цех85', href: '#'},
    {title: 'Контакты', href: '#'},
    {
        title: 'База знаний',
        href: '#'
    },
    {title: 'Поддержка', href: '#'}
];
