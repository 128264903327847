import {RouteObject} from 'react-router';
import MainLayout from 'components/layouts/MainLayout';
import {Authorization, Home, News, Profile} from 'pages';
import {ROUTES} from 'utils/constants';
import {UserAccounts} from './pages/Users/pages/Accounts';
import {PermissionsList} from './pages/Permissions/pages/List';
import {PermissionsGroups} from './pages/Permissions/pages/Groups';
import {PermissionsRoles} from './pages/Permissions/pages/Roles';

export const routes: RouteObject[] = [
    {
        element: <MainLayout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: ROUTES.AUTH,
                element: <Authorization />
            },
            {
                path: ROUTES.SSO_AUTH,
                element: <Authorization />
            },
            {
                path: ROUTES.PROFILE,
                element: <Profile />
            },
            {
                path: ROUTES.NEWS,
                element: <News />
            },
            {
                path: ROUTES.REQUESTS,
                element: <div />
            },
            {
                path: ROUTES.STORAGE,
                element: <div />
            },
            {
                path: ROUTES.AUDITS,
                element: <div />
            },
            {
                path: ROUTES.TABLE,
                element: <div />
            },
            {
                path: ROUTES.SUBSTITUTIONS,
                element: <div />
            },
            {
                path: ROUTES.VACATIONS,
                element: <div />
            },
            {
                path: ROUTES.CONTACTS,
                element: <div />
            },
            {
                path: ROUTES.BIRTHDAYS,
                element: <div />
            },
            {
                path: ROUTES.STRUCTURE,
                element: <div />
            },
            {
                path: ROUTES.WIKI,
                element: <div />
            },
            {
                path: ROUTES.REQUESTS,
                element: <div />
            },
            {
                path: ROUTES.SURVEYS,
                element: <div />
            },
            {
                path: ROUTES.TESTS,
                element: <div />
            },
            {
                path: ROUTES.CHECK_LISTS,
                element: <div />
            },
            {
                path: ROUTES.AUDITS,
                element: <div />
            },
            {
                path: ROUTES.STORAGE,
                element: <div />
            },
            {
                path: ROUTES.THANK,
                element: <div />
            },
            {
                path: ROUTES.USER,
                children: [
                    {index: true, element: <div />},
                    {
                        path: ROUTES.USER_SERVICE_ACCOUNTS,
                        element: <div />
                    },
                    {
                        path: ROUTES.USER_ACCOUNTS,
                        element: <UserAccounts />
                    },
                    {
                        path: ROUTES.USER_GROUPS,
                        element: <div />
                    },
                    {
                        path: ROUTES.USER_PROFILES,
                        element: <div />
                    },
                    {
                        path: ROUTES.USER_SCHEDULERS,
                        element: <div />
                    },
                    {
                        path: ROUTES.USER_POSITIONS,
                        element: <div />
                    },
                    {
                        path: ROUTES.USER_DIVISIONS,
                        element: <div />
                    }
                ]
            },
            {
                path: ROUTES.PERMISSIONS,
                children: [
                    {index: true, element: <div />},
                    {
                        path: ROUTES.PERMISSIONS_LIST,
                        element: <PermissionsList />
                    },
                    {
                        path: ROUTES.PERMISSIONS_GROUPS,
                        element: <PermissionsGroups />
                    },
                    {
                        path: ROUTES.PERMISSIONS_ROLES,
                        element: <PermissionsRoles />
                    }
                ]
            },
            {
                path: ROUTES.LOGS,
                element: <div />
            },
            {
                path: ROUTES.HELP,
                element: <div />
            },
            {
                path: ROUTES.DOCUMENTATION,
                element: <div />
            },
            {
                path: ROUTES.IDEA,
                element: <div />
            }
        ]
    }
];
