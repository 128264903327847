/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BANDA API
 * OpenAPI spec version: 1.0.0
 */
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult
} from '@tanstack/react-query';
import {useMutation, useQuery} from '@tanstack/react-query';
import type {
    GetApiBandaGetfileId401,
    GetApiBandaGetfileId403,
    GetApiBandaGetfileId422,
    GetApiBandaGetfileId500,
    PostApiBandaUpload200,
    PostApiBandaUpload401,
    PostApiBandaUpload403,
    PostApiBandaUpload422,
    PostApiBandaUpload500,
    PostApiBandaUploadAvatar200,
    PostApiBandaUploadAvatar401,
    PostApiBandaUploadAvatar403,
    PostApiBandaUploadAvatar422,
    PostApiBandaUploadAvatar500,
    PostApiBandaUploadAvatarBody,
    PostApiBandaUploadBody
} from './bandaApi.schemas';
import {axiosInstance} from './mutator/axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Максимальный размер: 200мб
 Максимальный количество файлов: 15
 <aside class="notice">
 Разрешенные расширения:
 Изображения
 .gif
 .heic
 .jpeg, .jpg
 .png
 .svg
 .webp

 Документы
 .doc, .docx
 .key
 .odt
 .pdf
 ppt, .pptx, .pps, .ppsx
 .xls, .xlsx

 Аудио
 .mp3
 .m4a
 .ogg
 .wav

 Видео
 .avi
 .mpg
 .mp4, .m4v
 .mov
 .ogv
 .3gp
 .3g2
 .webm
 .wmv

 Архивы
 .zip
 .rar
 .7z
 </aside>
 * @summary Загрузка файлов
 */
export const postApiBandaUpload = (postApiBandaUploadBody: PostApiBandaUploadBody) => {
    const formData = new FormData();
    postApiBandaUploadBody.files.forEach((value) => formData.append('files', value));
    formData.append('source', postApiBandaUploadBody.source);

    return axiosInstance<PostApiBandaUpload200>({
        url: `/api/banda/upload`,
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData
    });
};

export const getPostApiBandaUploadMutationOptions = <
    TError = PostApiBandaUpload401 | PostApiBandaUpload403 | PostApiBandaUpload422 | PostApiBandaUpload500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaUpload>>,
        TError,
        {data: PostApiBandaUploadBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiBandaUpload>>,
    TError,
    {data: PostApiBandaUploadBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiBandaUpload>>,
        {data: PostApiBandaUploadBody}
    > = (props) => {
        const {data} = props ?? {};

        return postApiBandaUpload(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiBandaUploadMutationResult = NonNullable<Awaited<ReturnType<typeof postApiBandaUpload>>>;
export type PostApiBandaUploadMutationBody = PostApiBandaUploadBody;
export type PostApiBandaUploadMutationError =
    | PostApiBandaUpload401
    | PostApiBandaUpload403
    | PostApiBandaUpload422
    | PostApiBandaUpload500;

/**
 * @summary Загрузка файлов
 */
export const usePostApiBandaUpload = <
    TError = PostApiBandaUpload401 | PostApiBandaUpload403 | PostApiBandaUpload422 | PostApiBandaUpload500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaUpload>>,
        TError,
        {data: PostApiBandaUploadBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiBandaUpload>>,
    TError,
    {data: PostApiBandaUploadBody},
    TContext
> => {
    const mutationOptions = getPostApiBandaUploadMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Загрузка аватара
 */
export const postApiBandaUploadAvatar = (postApiBandaUploadAvatarBody: PostApiBandaUploadAvatarBody) => {
    const formData = new FormData();
    formData.append('file', postApiBandaUploadAvatarBody.file);

    return axiosInstance<PostApiBandaUploadAvatar200>({
        url: `/api/banda/upload/avatar`,
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData
    });
};

export const getPostApiBandaUploadAvatarMutationOptions = <
    TError =
        | PostApiBandaUploadAvatar401
        | PostApiBandaUploadAvatar403
        | PostApiBandaUploadAvatar422
        | PostApiBandaUploadAvatar500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaUploadAvatar>>,
        TError,
        {data: PostApiBandaUploadAvatarBody},
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<typeof postApiBandaUploadAvatar>>,
    TError,
    {data: PostApiBandaUploadAvatarBody},
    TContext
> => {
    const {mutation: mutationOptions} = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof postApiBandaUploadAvatar>>,
        {data: PostApiBandaUploadAvatarBody}
    > = (props) => {
        const {data} = props ?? {};

        return postApiBandaUploadAvatar(data);
    };

    return {mutationFn, ...mutationOptions};
};

export type PostApiBandaUploadAvatarMutationResult = NonNullable<Awaited<ReturnType<typeof postApiBandaUploadAvatar>>>;
export type PostApiBandaUploadAvatarMutationBody = PostApiBandaUploadAvatarBody;
export type PostApiBandaUploadAvatarMutationError =
    | PostApiBandaUploadAvatar401
    | PostApiBandaUploadAvatar403
    | PostApiBandaUploadAvatar422
    | PostApiBandaUploadAvatar500;

/**
 * @summary Загрузка аватара
 */
export const usePostApiBandaUploadAvatar = <
    TError =
        | PostApiBandaUploadAvatar401
        | PostApiBandaUploadAvatar403
        | PostApiBandaUploadAvatar422
        | PostApiBandaUploadAvatar500,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof postApiBandaUploadAvatar>>,
        TError,
        {data: PostApiBandaUploadAvatarBody},
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<typeof postApiBandaUploadAvatar>>,
    TError,
    {data: PostApiBandaUploadAvatarBody},
    TContext
> => {
    const mutationOptions = getPostApiBandaUploadAvatarMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * <aside class="notice">
 Альернативный вариант передачи токена: через cookie accessToken
 </aside>
 * @summary Отдача файла
 */
export const getApiBandaGetfileId = (id: string, signal?: AbortSignal) => {
    return axiosInstance<Blob>({url: `/api/banda/getfile/${id}`, method: 'GET', responseType: 'blob', signal});
};

export const getGetApiBandaGetfileIdQueryKey = (id: string) => {
    return [`/api/banda/getfile/${id}`] as const;
};

export const getGetApiBandaGetfileIdQueryOptions = <
    TData = Awaited<ReturnType<typeof getApiBandaGetfileId>>,
    TError = GetApiBandaGetfileId401 | GetApiBandaGetfileId403 | GetApiBandaGetfileId422 | GetApiBandaGetfileId500
>(
    id: string,
    options?: {query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaGetfileId>>, TError, TData>>}
) => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetApiBandaGetfileIdQueryKey(id);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiBandaGetfileId>>> = ({signal}) =>
        getApiBandaGetfileId(id, signal);

    return {
        queryKey,
        queryFn,
        enabled: !!id,
        ...queryOptions
    } as UseQueryOptions<Awaited<ReturnType<typeof getApiBandaGetfileId>>, TError, TData> & {queryKey: QueryKey};
};

export type GetApiBandaGetfileIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiBandaGetfileId>>>;
export type GetApiBandaGetfileIdQueryError =
    | GetApiBandaGetfileId401
    | GetApiBandaGetfileId403
    | GetApiBandaGetfileId422
    | GetApiBandaGetfileId500;

/**
 * @summary Отдача файла
 */
export const useGetApiBandaGetfileId = <
    TData = Awaited<ReturnType<typeof getApiBandaGetfileId>>,
    TError = GetApiBandaGetfileId401 | GetApiBandaGetfileId403 | GetApiBandaGetfileId422 | GetApiBandaGetfileId500
>(
    id: string,
    options?: {query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiBandaGetfileId>>, TError, TData>>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const queryOptions = getGetApiBandaGetfileIdQueryOptions(id, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryOptions.queryKey;

    return query;
};
