import {useForm} from 'react-hook-form';
import {Paper} from 'components/accessory/Paper';
import {Stack} from '@mui/material';
import {Form} from 'components/form/Form';
import React from 'react';
import {TableHeader} from './components/TableHeader';
import PermissionRolesTable from './components/PermissionRolesTable';

export const PermissionsRoles = () => {
    const form = useForm();

    return (
        <Paper sx={{p: 3, height: '100%'}}>
            <Stack gap={2}>
                {/*<UsersTabPanel />*/}
                <TableHeader />
                <Form form={form}>
                    <PermissionRolesTable form={form} />
                </Form>
            </Stack>
        </Paper>
    );
};
