/* eslint-disable camelcase */
import {Button, Divider, Stack, Typography} from '@mui/material';
import {useForm} from 'react-hook-form';
import {Form} from 'components/form/Form';
import * as React from 'react';
import {useEffect} from 'react';
import {PlusIcon} from 'assets/icons';
import {openModal} from 'context/modalEffector';
import {AddEditGroupModal} from './AddEditGroupModal';
import {useQsParams} from 'utils/hooks/useQsParams';

export const TableHeader = () => {
    const form = useForm();
    const {reset} = form;
    const [{per_page}] = useQsParams();
    useEffect(() => {
        reset({per_page});
    });
    const handleOpenModal = () => {
        openModal({
            title: 'Добавление группы',
            body: <AddEditGroupModal />,
            maxWidth: 'sm'
            // sx: ModalWidth(422)
        });
    };
    return (
        <Form form={form}>
            <Stack gap={2}>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    gap={2}>
                    <Typography variant={'h5'}>Список групп</Typography>
                    <Button variant={'contained'} startIcon={<PlusIcon />} onClick={handleOpenModal}>
                        Добавить группу
                    </Button>
                </Stack>
                <Divider sx={{mx: -3}} />
                {/*<Stack*/}
                {/*    direction={'row'}*/}
                {/*    justifyContent={'space-between'}*/}
                {/*    alignItems={'center'}*/}
                {/*    flexWrap={'wrap'}*/}
                {/*    gap={2}>*/}
                {/*    <TablePerPage />*/}
                {/*</Stack>*/}
            </Stack>
        </Form>
    );
};
