import {ThemeOptions} from '@mui/material';

export const whiteTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#7367f0',
            200: '#f067692e',
            contrastText: '#EEEEEE'
        },
        secondary: {main: '#FF0000'},
        action: {
            selected: 'rgba(47, 43, 61, 0.08)',
            disabled: '#C1BFC5'
        },
        background: {
            body: '#f8f7fa',
            default: '#EEEFF4'
        },
        divider: '#0000001f',

        text: {
            primary: '#2f2b3de6',
            secondary: '#6D6B77',
            disabled: '#ACAAB1',
            white: '#FFFFFF'
        }
    }
};
