import * as React from 'react';
import {Paper} from 'components/accessory/Paper';
import {Link, Stack, Theme, Typography} from '@mui/material';
import {paperPaddingMixin} from 'utils/mixins';
import {useAuthContext} from 'context/authContext';
import {fullName} from 'utils/functions';
import {GetApiV1AuthenticationUser200} from 'api/bandaApi.schemas';
import dayjs from 'dayjs';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CakeIcon from '@mui/icons-material/Cake';
import StairsOutlinedIcon from '@mui/icons-material/StairsOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';

export const ProfileInfo = () => {
    const {me} = useAuthContext();
    return (
        <Paper sx={{...paperPaddingMixin, width: '100%'}}>
            <Stack gap={3}>
                {PROFILE_STRUCTURE.map((chapter, id) => (
                    <Stack key={id} gap={2}>
                        <Typography variant={'body2'} sx={{textTransform: 'uppercase'}} color={'text.disabled'}>
                            {chapter?.title}
                        </Typography>
                        <>
                            {chapter.data.map((infoLine, lineId) => (
                                <>
                                    <Stack direction={'row'} gap={1} alignItems={'center'} key={lineId}>
                                        {infoLine.icon}
                                        <Typography color={'text.secondary'} fontWeight={'500'}>
                                            {infoLine.title}:
                                        </Typography>
                                        <Typography color={'text.secondary'}>
                                            {(infoLine.value?.(me) as string) || ' - '}
                                        </Typography>
                                    </Stack>
                                </>
                            ))}
                        </>
                    </Stack>
                ))}
            </Stack>
        </Paper>
    );
};

type meType = GetApiV1AuthenticationUser200;
const iconColor = {color: (theme: Theme) => theme.palette.text.secondary};
const PROFILE_STRUCTURE = [
    {
        title: 'Личная информация',
        data: [
            {
                title: 'ФИО',
                value: (me: meType) => fullName(me),
                icon: <PersonOutlineOutlinedIcon sx={{...iconColor}} />
            },
            {
                title: 'День рождения',
                value: (me: meType) => dayjs(me?.date_of_birth as string).format('DD.MM.YYYY'),
                icon: <CakeIcon sx={{...iconColor}} />
            },
            {
                title: 'Должность',
                value: () => {},
                icon: <StairsOutlinedIcon sx={{...iconColor}} />
            }
        ]
    },
    {
        title: 'Контакты',
        data: [
            {
                title: 'Номер телефона',
                value: (me: meType) => me?.phone,
                icon: <PhoneInTalkOutlinedIcon sx={{...iconColor}} />
            },
            {
                title: 'Email',
                value: (me: meType) => (
                    <Link href={`mailto:test@example.com`} color={'textPrimary'} underline={'none'}>
                        {me?.email as string}
                    </Link>
                ),
                icon: <EmailOutlinedIcon sx={{...iconColor}} />
            }
        ]
    },
    {
        title: 'Дополнительно',
        data: [
            {
                title: 'Импортирован',
                value: (me: meType) => me?.source_id,
                icon: <ImportExportOutlinedIcon sx={{...iconColor}} />
            }
        ]
    }
];
