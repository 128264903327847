export {ReactComponent as FullLogoIcon} from './logo-tseh.svg';
export {ReactComponent as SmallLogoIcon} from './logo-small-tseh.svg';
export {ReactComponent as BackIcon} from './back-logo.svg';
export {ReactComponent as ProfileIcon} from './user-icon.svg';
export {ReactComponent as SchedulerIcon} from './scheduler-icon.svg';
export {ReactComponent as NewsIcon} from './news-icon.svg';
export {ReactComponent as RequestIcon} from './bell-icon.svg';
export {ReactComponent as KnowledgeIcon} from './school-icon.svg';
export {ReactComponent as StorageIcon} from './folder-icon.svg';
export {ReactComponent as AuditsIcon} from './headphones-icon.svg';
export {ReactComponent as CircleIcon} from './circle-icon.svg';
export {ReactComponent as UsersIcon} from './users-icon.svg';
export {ReactComponent as CircleDotIcon} from './circle-dot-icon.svg';
