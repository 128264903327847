import {CSSObject, Theme} from '@mui/material/styles';

export const transitionMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    })
});

export const paperPaddingMixin = {p: 3};

export const logoColorMixin = (thisTheme: 'dark' | 'light') => ({
    '& path': {
        fill: (theme: Theme) =>
            (thisTheme === 'dark' ? theme.palette.text.primary : theme.palette.secondary.main) + ' !important'
    },
    '& rect': {
        stroke: (theme: Theme) =>
            (thisTheme === 'dark' ? theme.palette.text.primary : theme.palette.secondary.main) + ' !important'
    }
});
export const shadow = '0 .125rem .5rem 0 rgba(47,43,61,.12)';
export const svgColorMixin = (color: string) => ({'& path, rect, circle': {stroke: color}});

export const iconsShadow = {
    dark: '0 0.0625rem 0.375rem 0 rgba(19, 17, 32, 0.16)',
    light: '0 0.0625rem 0.375rem 0 rgba(47, 43, 61, 0.1)'
};
export const boxShadow = {
    dark: '0 0.25rem 1.125rem 0 rgba(19, 17, 32, 0.22)',
    light: '0 0.25rem 1.125rem 0 rgba(47, 43, 61, 0.16)'
};
export const backdropColor = {
    dark: '#171925',
    light: '#97959e'
};
