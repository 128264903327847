import {typography} from './typography';
import {alpha, lighten, ThemeOptions} from '@mui/material';

declare module '@mui/material/styles' {
    interface PaletteColor {
        100?: string;
        200?: string;
        300?: string;
        400?: string;
        500?: string;
        600?: string;
        700?: string;
        800?: string;
        900?: string;
    }

    interface TypeBackground {
        body?: string;
    }

    interface TypeText {
        white?: string;
    }
}

export const baseTheme: ThemeOptions = {
    typography,
    shape: {
        borderRadius: 5
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1000,
            lg: 1275,
            xl: 1736
        }
    },
    mixins: {
        toolbar: {
            minHeight: 54
        }
    },
    components: {
        MuiContainer: {
            defaultProps: {
                maxWidth: 'xl'
            },
            styleOverrides: {
                root: ({theme}) => ({
                    [theme.breakpoints.down('md')]: {
                        padding: '0 20px'
                    },
                    [theme.breakpoints.up('md')]: {
                        padding: '0 60px'
                    }
                })
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    margin: 0,
                    position: 'absolute',
                    top: 'calc(100% - 1px)',
                    left: 0
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: ({theme}) => ({
                    transition: 'color 0s',
                    '&.Mui-selected': {
                        '&:hover': {backgroundColor: theme.palette.background.paper},
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.primary.main
                        },
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.primary.main
                    },
                    '& .MuiTouchRipple-root': {
                        color: alpha(theme.palette.primary.main, 0.25)
                    }
                })
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: ({theme}) => ({
                    gap: 12,
                    marginRight: 8,
                    marginLeft: 8,
                    borderRadius: 6,
                    minHeight: '36px !important',
                    '&.Mui-selected': {
                        background: theme.palette.background.paper,
                        color: theme.palette.primary.main,
                        '&:hover': {
                            background: theme.palette.background.paper
                        }
                    },
                    '& .MuiTouchRipple-root': {
                        color: alpha(theme.palette.primary.main, 0.25)
                    }
                })
            }
        },
        MuiInputBase: {
            defaultProps: {
                size: 'small'
            },
            styleOverrides: {
                multiline: {alignItems: 'flex-start'},
                root: ({theme}) => ({
                    '&:before,:after': {
                        borderBottom: 'none !important'
                    }
                }),
                sizeSmall: {},
                input: {}
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {paddingRight: '8px', height: '100%'},
                input: ({theme}) => ({
                    '&::placeholder': {
                        color: theme.palette.text.disabled
                    }
                }),
                notchedOutline: ({theme}) => ({
                    transition: theme.transitions.create('border-color', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.standard
                    })
                })
            }
        },
        MuiSvgIcon: {
            defaultProps: {
                fontSize: 'small'
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: ({theme}) => ({
                    backgroundColor: theme.palette.background.default
                })
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: ({theme}) => ({background: theme.palette.background.paper})
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none !important',
                    borderRadius: '6px',
                    fontWeight: 400
                },
                containedPrimary: ({theme}) => ({
                    '&:hover': {
                        backgroundColor: lighten(theme.palette.primary.main, 0.2)
                    },
                    color: theme.palette.text.white
                }),
                containedSecondary: ({theme}) => ({
                    '&:hover': {
                        backgroundColor: lighten(theme.palette.secondary.main, 0.2)
                    },
                    color: theme.palette.text.white
                })
            }
        }
    }
};
