import React, {useCallback, useRef, useState} from 'react';
import {Box, Button, Stack, Theme, Typography, useMediaQuery} from '@mui/material';
import {useDropzone} from 'react-dropzone';
import ReactCrop, {Crop} from 'react-image-crop';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import 'react-image-crop/dist/ReactCrop.css';
import onCreateCroppedPreview from 'utils/functions/onCreateCroppedPreview';
import {closeModal} from 'context/modalEffector';

type PhotoModalType = {
    setPhoto: any;
    me: any;
};

export const UploadAvatarModal = ({setPhoto, me}: PhotoModalType) => {
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const avatarUrl = me?.avatar?.url;
    // в формате base64
    const [file, setFile] = useState<any>(avatarUrl);
    // в формате File
    const [defaultFile, setDefaultFile] = useState<File | null>(null);
    const [crop, setCrop] = useState<Crop>({
        unit: '%',
        x: 0,
        y: 0,
        width: 100,
        height: 100
    });
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const ext = {
        '.pdf': [],
        '.png': [],
        '.jpeg': [],
        '.jpg': [],
        '.bmp': [],
        '.tiff': [],
        '.heic': []
        // '.heif': [],
        // '.jfif': []
    };
    const uploadNewAvatar = (cropFile: any) => {
        setPhoto(cropFile);
        closeModal();
        clear();
    };
    const onDrop = useCallback((acceptedFiles: any) => {
        setDefaultFile(acceptedFiles?.[0]);
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setFile(reader.result);
        });
        reader.readAsDataURL(acceptedFiles[0]);
    }, []);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: ext
    });
    const clear = () => {
        setCompletedCrop(null);
        setDefaultFile(null);
        setFile(null);
    };
    return (
        <>
            <Box sx={{p: 2, border: '1px solid #dee2e6'}}>
                {file ? (
                    <Box
                        sx={{
                            width: '100%',
                            height: 340,
                            maxWidth: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <ReactCrop
                            crop={crop}
                            aspect={1}
                            onChange={(c: any) => {
                                console.log(c);
                                setCrop(c);
                            }}
                            onComplete={(c: any) => {
                                console.log(c);
                                setCompletedCrop(c);
                            }}>
                            <img src={file} ref={imgRef} style={{maxHeight: '340px'}} crossOrigin='anonymous' />
                        </ReactCrop>
                    </Box>
                ) : (
                    <Button
                        variant={'contained'}
                        color={'inherit'}
                        {...getRootProps()}
                        sx={{
                            mx: 'auto',
                            width: '100%',
                            height: 340,
                            maxWidth: '100%',
                            p: 0,
                            color: 'text.primaryConst',
                            background: '#f4f4f4 !important',
                            boxShadow: 'none !important'
                        }}>
                        <Stack sx={{alignItems: 'center'}} spacing={3}>
                            <AddAPhotoIcon />
                            <Typography sx={{mt: '16px !important'}} color='text.primaryConst'>
                                Добавьте фото
                            </Typography>
                        </Stack>
                    </Button>
                )}
                <input {...getInputProps()} accept='image/*' />
            </Box>
            <canvas ref={previewCanvasRef} style={{display: 'none'}} />
            <Stack direction={isMd ? 'row' : 'column'} spacing={1} justifyContent={'flex-end'} mt={2}>
                <Button onClick={clear}>Очистить</Button>
                <Button
                    onClick={() => {
                        // удаление аватара
                        if (!file) {
                            uploadNewAvatar(null);
                        } else if (!completedCrop) {
                            //загрузили фотку но не меняли её
                            if (defaultFile) {
                                uploadNewAvatar(defaultFile);
                                //открыли дефолтную фотку и нажали сохранить
                            } else {
                                closeModal();
                            }
                            //загрузили фотку и отредачили
                        } else {
                            onCreateCroppedPreview({
                                completedCrop,
                                previewCanvasRef,
                                imgRef,
                                uploadNewAvatar,
                                fileName: defaultFile?.name || 'avatar'
                            });
                        }
                    }}>
                    Сохранить
                </Button>
            </Stack>
        </>
    );
};
