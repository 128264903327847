import {stringAvatar} from 'utils/functions';
import * as React from 'react';
import {Avatar} from '@mui/material';

export const MainAvatar = ({dot, user}: {dot?: string | boolean; user: any}) => {
    return (
        <Avatar
            {...stringAvatar(user?.first_name + ' ' + user?.last_name)}
            src={user?.avatar?.url}
            sx={{
                width: 40,
                height: 40,
                fontSize: '18px',
                overflow: 'initial',
                position: 'relative',
                '& img': {
                    borderRadius: '100%'
                },
                ':after': dot
                    ? {
                          backgroundColor: (theme) =>
                              //@ts-ignore
                              typeof dot === 'string' ? theme.palette?.[dot] : theme.palette.success.main,
                          content: '""',
                          position: 'absolute',
                          bottom: 0,
                          right: '3px',
                          width: '8px',
                          height: '8px',
                          borderRadius: '100%',
                          boxShadow: '0 0 0 2px #fff'
                      }
                    : {}
            }}
        />
    );
};
