import {ThemeOptions} from '@mui/material';

export const blackTheme: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#7367f0',
            200: '#f067692e',
            contrastText: '#2f2b3d'
        },
        secondary: {
            main: '#FF3F3F'
        },
        background: {
            default: '#25293C',
            paper: '#2F3349'
        },
        action: {
            selected: 'rgba(47, 43, 61, 0.08)'
        },
        text: {primary: '#E1DEF5E6', white: '#FFFFFF'}
    }
};
