import {Stack} from '@mui/material';
import bg from 'assets/images/login-background.png';
import bg2 from 'assets/images/login-background2.png';

export const MainImage = () => {
    return (
        <Stack
            sx={{
                zIndex: 1,
                width: '100%',
                height: '100%',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: (theme) => theme.palette.background.body
            }}>
            <img
                src={bg}
                style={{
                    maxHeight: '85%',
                    maxWidth: '85%',
                    marginTop: '20px',
                    marginBottom: '20px'
                }}
            />
            <img
                src={bg2}
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '35%',
                    bottom: 0
                }}
            />
        </Stack>
    );
};
