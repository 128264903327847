import {Link, Stack} from '@mui/material';
import {drawerWidth} from 'utils/constants';
import {FOOTER_LINKS} from 'utils/constants/menu';

export const Footer = ({blockOpen}: {blockOpen?: boolean}) => {
    return (
        <Stack
            direction={'row'}
            component={'footer'}
            sx={{
                m: {xs: 1, md: 2},
                marginTop: 'auto !important',
                paddingTop: {xs: 1, md: 2},
                marginLeft: (theme) => ({
                    md: blockOpen ? `calc(${drawerWidth + 16}px + 1px)` : `calc(${theme.spacing(10)} + 1px)`
                }),
                justifyContent: 'space-between'
            }}>
            <Stack>© 2024, Сделано с ❤️ ваш Тех85</Stack>
            <Stack direction={'row'} gap={2}>
                {FOOTER_LINKS.map((link) => (
                    <Link key={link.title} href={link.href} color={'text.secondary'} underline='none'>
                        {link.title}
                    </Link>
                ))}
            </Stack>
        </Stack>
    );
};
