import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';

import {IconButton, Menu, MenuItem, Stack} from '@mui/material';
import {$theme, changeTheme} from 'context/themeEffector';
import {MODE} from 'theme';
import * as React from 'react';
import {useUnit} from 'effector-react/effector-react.mjs';

export const SwitchTheme = ({isOpenLeftMenu}: {isOpenLeftMenu: boolean}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpenMenu = Boolean(anchorEl);
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton
                id='switch-theme-button'
                aria-controls={isOpenLeftMenu ? 'switch-theme-menu' : undefined}
                aria-haspopup='true'
                onClick={handleOpenMenu}
                aria-expanded={isOpenLeftMenu ? 'true' : undefined}>
                {THEME_ICONS[useUnit($theme)]}
            </IconButton>
            <Menu
                id='switch-theme-menu'
                anchorEl={anchorEl}
                open={isOpenMenu}
                slotProps={{paper: {sx: {mt: 1, mb: 2}, className: 'custom-scroll'}}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'switch-theme-button'
                }}>
                <Stack gap={0.5}>
                    {THEME_ITEMS.map((themeItem) => (
                        <MenuItem
                            key={themeItem.value}
                            onClick={() => {
                                changeTheme(themeItem.value);
                                handleCloseMenu();
                            }}
                            selected={useUnit($theme) === themeItem.value}>
                            {themeItem.icon} {themeItem.text}
                        </MenuItem>
                    ))}
                </Stack>
            </Menu>
        </>
    );
};
const THEME_ICONS = {
    [MODE.LIGHT]: <WbSunnyOutlinedIcon fontSize={'medium'} />,
    [MODE.DARK]: <DarkModeOutlinedIcon fontSize={'medium'} />,
    [MODE.SYSTEM]: <ComputerOutlinedIcon fontSize={'medium'} />
};
const THEME_ITEMS = [
    {text: 'Светлая', icon: THEME_ICONS[MODE.LIGHT], value: MODE.LIGHT},
    {text: 'Тёмная', icon: THEME_ICONS[MODE.DARK], value: MODE.DARK},
    {text: 'Системная', icon: THEME_ICONS[MODE.SYSTEM], value: MODE.SYSTEM}
];
