import {ThemeOptions} from '@mui/material';

export const whiteTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#7367f0',
            200: '#f067692e',
            contrastText: '#EEEEEE'
        },
        secondary: {main: '#FF0000'},
        action: {
            hover: 'rgba(47, 43, 61, 0.08)',
            selected: '#e9e7fd',
            disabled: '#C1BFC5'
        },
        background: {
            body: '#f8f7fa',
            default: '#EEEFF4',
            paper: '#FFFFFF'
        },
        divider: '#0000001f',
        common: {border: '#d1d0d4', shadow: '#FFF'},
        grey: {500: '#2f2b3d'},
        text: {
            primary: '#6d6b77',
            secondary: '#6D6B77',
            disabled: '#ACAAB1',
            white: '#FFFFFF',
            heading: '#444050'
        },

        success: {
            main: '#28c76f'
        }
    }
};
