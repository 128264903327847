import {Stack} from '@mui/material';
import cake from 'assets/images/cake.png';

export const MainImage = () => {
    return (
        <Stack
            sx={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${cake})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center 60%'
            }}
        />
    );
};
