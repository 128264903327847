import {createEvent, createStore, sample} from 'effector';
import {MODE} from 'theme';

export const changeTheme = createEvent<string>();
export const $theme = createStore(localStorage.getItem('theme') || MODE.SYSTEM);

sample({
    clock: changeTheme,
    fn: (value) => {
        localStorage.setItem('theme', value);
        return value;
    },
    target: $theme
});
