/* eslint-disable camelcase */
import * as React from 'react';
import {useEffect} from 'react';
import {Avatar, Chip, Stack, SxProps, Typography} from '@mui/material';
import {useGetApiBandaSystemEmployeesAccountsShow} from 'api/система';
import {TableLayout} from 'components/layouts/MainLayout/components/TableLayout';
import dayjs from 'dayjs';
import {useQsParams} from 'utils/hooks/useQsParams';
import {ArrowDownIcon, ArrowUpIcon} from 'assets/icons';
import {stringAvatar} from 'utils/functions';
import {FormTextField} from 'components/form/FormTextField';
import {FormDatePicker} from 'components/form/FormDatePicker';
import {FormSelect} from 'components/form/FormSelect';
import {FormAutocompleteAsyncPaginate} from 'components/form/FormAutocompleteAsyncPaginate';

export default function UsersTable({form}: {form: any}) {
    const {watch, reset} = form;
    const [params, , setParamsSmart] = useQsParams();
    useEffect(() => {
        reset({
            page: params.page || 1,
            sort: params.sort,
            last_name: params.last_name,
            email: params.email,
            source_id: params.source_id,
            date_of_birth: params.date_of_birth,
            hiring_date: params.hiring_date,
            department: params.department,
            position: params.position,
            is_active: params.is_active,
            status: params.status
        });
    }, []);
    const {
        data: users,
        isLoading,
        refetch
    } = useGetApiBandaSystemEmployeesAccountsShow(
        {},
        {
            page: params.page || 1,
            sort: params.sort,
            per_page: params.per_page || '25',
            'filter[full_name]': params.full_name || '',
            'filter[first_name]': '',
            'filter[last_name]': '',
            'filter[patronymic]': '',
            'filter[email]': params.email || '',
            'filter[source_id]': params.source_id || '',
            'filter[date_of_birth]': dayjs(params.date_of_birth).isValid() ? params.date_of_birth : '' || '',
            // 'filter[hiring_date]': dayjs(params.hiring_date).isValid() ? params.hiring_date : '' || '',
            'filter[search_department]': params.department || '',
            'filter[search_position]': params.position || '',
            is_active: params.is_active ? Boolean(+params.is_active) : undefined,
            //@ts-ignore
            'status[]': params.status?.length ? params.status?.map((e: any) => e.value) : undefined
        }
    );
    const sortClick = (sortName: string) => {
        const newValue = params.sort?.includes('-' + sortName)
            ? undefined
            : params.sort?.includes(sortName)
            ? '-' + sortName
            : sortName;
        setParamsSmart({sort: newValue});
    };
    return (
        <TableLayout
            columns={REPORTS_STRUCTURE(sortClick)}
            rows={users?.data || []}
            isLoading={isLoading}
            filterRow
            pagination={users?.pagination}
            negativeMargin
            minWidth={1500}
        />
    );
}
const SortIcon = ({sortName}: {sortName: string}) => {
    const [params] = useQsParams();
    return (
        <>
            {params.sort?.includes('-' + sortName) ? (
                <ArrowUpIcon style={{width: 16, height: 16}} />
            ) : params.sort?.includes(sortName) ? (
                <ArrowDownIcon style={{width: 16, height: 16}} />
            ) : (
                ''
            )}
        </>
    );
};
const SortButton = ({
    title,
    sortName,
    sortClick,
    sx
}: {
    title: string;
    sortName: string;
    sortClick: any;
    sx?: SxProps;
}) => {
    return (
        <Stack
            direction={'row'}
            sx={{
                width: '100%',
                minHeight: 24,
                justifyContent: 'inherit',
                alignItems: 'center',
                cursor: 'pointer',
                ...sx
            }}
            onClick={() => sortClick(sortName)}>
            {title} <SortIcon sortName={sortName} />
        </Stack>
    );
};
const REPORTS_STRUCTURE = (sortClick: any) => [
    {
        title: <SortButton title={'Сотрудник'} sortName={'first_name'} sortClick={sortClick} />,
        filter: (
            <FormTextField
                name={'full_name'}
                size={'small'}
                isSearch
                // hasSearchIcon
                placeholder={'Поиск по ФИО'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => (
            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <Avatar
                    {...stringAvatar(item?.last_name + ' ' + item?.first_name)}
                    src={item?.avatar?.url}
                    sx={{
                        width: 36,
                        height: 36,
                        fontSize: '16px'
                    }}
                />
                <Stack>
                    <Typography variant={'body2'}>{item?.last_name + ' ' + item?.first_name + ' '}</Typography>
                    <Typography variant={'body2'}>{item?.patronymic || ''}</Typography>
                </Stack>
            </Stack>
        ),
        sx: {width: '10%'}
    },
    {
        title: <SortButton title={'Почта'} sortName={'email'} sortClick={sortClick} />,
        filter: (
            <FormTextField
                name={'email'}
                size={'small'}
                isSearch
                // hasSearchIcon
                placeholder={'Поиск по email'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.email,
        sx: {width: '10%'}
    },
    {
        title: <SortButton title={'Дата рождения'} sortName={'date_of_birth'} sortClick={sortClick} />,
        filter: <FormDatePicker name={'date_of_birth'} sx={{maxWidth: {xs: '100%', md: 300}}} isSearch />,
        value: (item: any) => dayjs(item?.date_of_birth).format('DD MMMM YYYY'),
        sx: {width: '10%'}
    },

    //TODO
    {
        title: 'Подразделение',
        filter: (
            <FormTextField
                name={'department'}
                size={'small'}
                isSearch
                placeholder={'Поиск по подразделению'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.employee_links?.map((link: any) => link.department.name)?.join('\n'),
        sx: {width: '10%'}
    },
    //TODO Добавить сортировку
    {
        title: 'Должность',
        filter: (
            <FormTextField
                name={'position'}
                size={'small'}
                isSearch
                placeholder={'Поиск по должности'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) =>
            item?.employee_links
                ?.filter((link: any) => link?.position_link?.name)
                .map((link: any) => link?.position_link?.name)
                .join('\n'),
        sx: {width: '10%'}
    },
    //TODO
    {
        title: <SortButton title={'Дата найма'} sortName={'hiring_date'} sortClick={sortClick} />,
        filter: <FormDatePicker name={'hiring_date'} sx={{maxWidth: {xs: '100%', md: 300}}} isSearch />,
        value: (item: any) =>
            item?.employee_links
                ?.filter((link: any) => link.department.name)
                ?.map((link: any) => dayjs(link.hiring_date).format('DD MMMM YYYY'))
                ?.join('\n'),
        // align: 'right',
        sx: {width: '10%'}
    },
    {
        title: <SortButton title={'Источник'} sortName={'source_id'} sortClick={sortClick} />,
        filter: (
            <FormTextField
                name={'source_id'}
                size={'small'}
                isSearch
                placeholder={'Поиск по источнику'}
                sx={{maxWidth: {xs: '100%', md: 300}}}
            />
        ),
        value: (item: any) => item?.employee_links?.map((link: any) => link.source_id)?.join('\n'),
        // align: 'right',
        sx: {width: '10%'}
    },
    {
        title: <SortButton title={'Активность'} sortName={'is_active'} sortClick={sortClick} />,
        filter: (
            <FormSelect
                name={'is_active'}
                options={ACTIVE_OPTIONS}
                optionValue={'value'}
                isSearch
                fullWidth
                placeholder={'Активность'}
                sx={{maxWidth: {xs: '100%', md: 300}, textTransform: 'none'}}
            />
        ),
        value: (item: any) => (item?.is_active ? 'Работает' : 'Уволен'),
        // align: 'right',
        sx: {width: '10%'}
    },
    {
        title: 'Статус',
        // <SortButton
        //     title={'Активность'}
        //     sortName={'status'}
        //     sortClick={sortClick}
        //     sx={{justifyContent: 'flex-end'}}
        // />
        filter: (
            <FormAutocompleteAsyncPaginate
                name={'status'}
                placeholder={'Статус'}
                optionValue={'value'}
                options={STATUS_OPTIONS}
                hasNextPage={false}
                multiple
                isSearch
                labelSx={{alignItems: 'flex-end'}}
                sx={{maxWidth: {xs: '100%', md: 300}, textTransform: 'none'}}
            />
            // <FormSelect
            //     name={'status'}
            //     options={STATUS_OPTIONS}
            //     optionValue={'value'}
            //     isSearch
            //     fullWidth
            //     placeholder={'Активность'}
            //     sx={{maxWidth: {xs: '100%', md: 300}, textTransform: 'none'}}
            // />
        ),
        value: (item: any) => (
            <Stack gap={0.5} justifyContent={'flex-end'} direction={'row'} flexWrap={'wrap'}>
                {item?.status.map((item: 'is_new' | 'is_trouble' | string) => (
                    <Chip
                        size={'small'}
                        label={CHIP_VARIANTS?.[item]?.text || item}
                        variant={'outlined'}
                        color={CHIP_VARIANTS?.[item]?.color || 'primary'}
                        key={item}
                    />
                ))}
            </Stack>
        ),
        align: 'right',
        sx: {width: '15%', minWidth: 150}
    }
];
const ACTIVE_OPTIONS = [
    {
        id: 1,
        name: 'Работает',
        value: '1'
    },
    {
        id: 2,
        name: 'Уволен',
        value: '0'
    }
];
const STATUS_OPTIONS = [
    // {
    //     id: 3,
    //     name: 'Без статуса',
    //     value: ''
    // },
    {
        id: 1,
        name: 'Новый',
        value: 'is_new'
    },
    {
        id: 2,
        name: 'Проблемный',
        value: 'is_trouble'
    }
];
const CHIP_VARIANTS: any = {
    is_new: {color: 'success', text: 'Новый'},
    is_trouble: {text: 'Проблемный', color: 'error'}
};
