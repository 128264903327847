import React from 'react';
import {SxProps} from '@mui/material';
import {ControllerProps, useController} from 'react-hook-form';
import {Theme} from '@mui/material/styles';
import {useQsParams} from 'utils/hooks/useQsParams';
import {Labeled} from 'components/form/Labeled';
import {DatePicker, DatePickerProps} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/ru';

export const FormDatePicker = (
    props: Omit<ControllerProps, 'render'> &
        Omit<DatePickerProps<any>, 'label'> & {
            label?: string;
            isFilter?: boolean;
            maxLength?: number;
            name: string;
            fullWidth?: boolean;
            direction?: 'row' | 'column';
            labelSx?: SxProps<Theme>;
            password?: boolean;
            helperText?: string;
            isSearch?: boolean;
            hasSearchIcon?: boolean;
            labelInInput?: boolean;
            closeIcon?: boolean;
        }
) => {
    const {
        name,
        rules,
        // placeholder,
        label,
        maxLength,
        sx,
        fullWidth,
        direction,
        labelSx,
        password = false,
        helperText,
        isSearch,
        hasSearchIcon,
        labelInInput,
        closeIcon = true,
        ...rest
    } = props;
    const {
        field,
        fieldState: {error}
    } = useController({name, rules, defaultValue: ''});
    const [, , setParamsSmart] = useQsParams();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
            <Labeled
                label={!labelInInput ? label : undefined}
                fullWidth={fullWidth}
                direction={direction}
                labelSx={labelSx}>
                <DatePicker
                    {...field}
                    format={'DD.MM.YYYY'}
                    sx={{maxWidth: '100%', ...sx}}
                    label={labelInInput ? label : undefined}
                    value={field.value ? dayjs(field.value) : null}
                    onAccept={(newValue: any) => {
                        field.onChange(dayjs(newValue).format('YYYY-MM-DD'));
                        if (isSearch)
                            setParamsSmart({
                                [name]: dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : '' || ''
                            });
                    }}
                    onChange={(newValue: any) => {
                        if (newValue?.$isDayjsObject) {
                            field.onChange(dayjs(newValue).format('YYYY-MM-DD'));
                            if (isSearch)
                                setParamsSmart({
                                    [name]: dayjs(newValue).isValid() ? dayjs(newValue).format('YYYY-MM-DD') : '' || ''
                                });
                        }
                        if (!newValue) {
                            field.onChange(newValue);
                            if (isSearch)
                                setParamsSmart({
                                    [name]: undefined
                                });
                        }
                    }}
                    {...rest}
                />
            </Labeled>
        </LocalizationProvider>
    );
};
