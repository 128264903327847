import {Avatar, Stack, Typography} from '@mui/material';
import {stringAvatar} from 'utils/functions';
import * as React from 'react';

export const UserInfo = ({me}: any) => {
    return (
        <Stack alignItems={'center'} gap={1} p={1} px={2}>
            <Avatar
                {...stringAvatar(me?.first_name + ' ' + me?.last_name)}
                sx={{
                    width: 48,
                    height: 48,
                    fontSize: '20px'
                }}
            />
            <Typography variant={'h5'} sx={{textWrap: 'wrap', textAlign: 'center', lineHeight: '22px'}}>
                {me?.last_name + ' ' + me?.first_name + ' ' + (me?.patronymic || '')}
            </Typography>
            <Typography color={'text.secondary'} variant={'body2'}>
                {me?.email}
            </Typography>
        </Stack>
    );
};
