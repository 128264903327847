import {Avatar, ButtonBase, Divider, Menu, MenuItem, Stack} from '@mui/material';
import {removeDuplicatesById, stringAvatar} from 'utils/functions';
import {UserInfo} from 'components/layouts/MainLayout/components/AppBar/components/UserInfo';
import {getTokens, removeTokens, setTokens} from 'utils/functions/auth';
import {UserLoginItem} from 'components/accessory/UserLoginItem';
import {ROUTES} from 'utils/constants';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import LogoutIcon from '@mui/icons-material/Logout';
import * as React from 'react';
import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useQueryClient} from '@tanstack/react-query';
import {useAuthContext} from 'context/authContext';
import {shadow} from 'utils/mixins';

export const UserMenu = ({isOpenLeftMenu}: {isOpenLeftMenu: boolean}) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {accessToken} = getTokens();
    const {me, usersLogin} = useAuthContext();
    const usersList = useMemo(() => {
        const filterWithoutAccess = usersLogin?.filter((user) => user.token !== accessToken && user.id !== me.id) || [];
        const removeDuplicateArray = removeDuplicatesById(filterWithoutAccess);
        localStorage.setItem(
            'tokens',
            JSON.stringify([accessToken, ...removeDuplicateArray.map((user) => user.token)])
        );
        return removeDuplicateArray;
    }, [usersLogin, accessToken]);
    const fullName = `${me?.first_name} ${me?.last_name}`;
    const exitClick = () => {
        removeTokens();
        queryClient.clear();
        navigate(ROUTES.AUTH);
        handleCloseMenu();
    };
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpenMenu = Boolean(anchorEl);
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <ButtonBase
                sx={{borderRadius: '100%'}}
                id='user-menu-button'
                aria-controls={isOpenLeftMenu ? 'user-basic-menu' : undefined}
                aria-haspopup='true'
                onClick={handleOpenMenu}
                aria-expanded={isOpenLeftMenu ? 'true' : undefined}>
                <Avatar
                    {...stringAvatar(fullName)}
                    sx={{
                        // backgroundColor: deepOrange[500],
                        width: 38,
                        height: 38,
                        fontSize: '16px'
                    }}
                />
            </ButtonBase>
            <Menu
                id='user-basic-menu'
                anchorEl={anchorEl}
                open={isOpenMenu}
                slotProps={{paper: {sx: {mt: 1, mb: 2, boxShadow: shadow}, className: 'custom-scroll'}}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'user-menu-button'
                }}>
                <Stack gap={0.5} sx={{width: 270}}>
                    <UserInfo me={me} />
                    <Divider sx={{my: 1}} />
                    {!!usersList.length && (
                        <>
                            {usersList?.map((user) => (
                                <MenuItem
                                    onClick={() => {
                                        // eslint-disable-next-line camelcase
                                        setTokens({access_token: user?.token as string});
                                        location.reload();
                                    }}
                                    key={user.id}>
                                    <UserLoginItem user={user} />
                                </MenuItem>
                            ))}
                            <Divider />
                        </>
                    )}
                    <MenuItem onClick={() => navigate(ROUTES.AUTH, {state: {openAuth: true}})}>
                        <PersonAddAlt1Icon fontSize={'small'} /> Добавить аккаунт
                    </MenuItem>
                    <MenuItem onClick={exitClick}>
                        <LogoutIcon fontSize={'small'} /> Выход
                    </MenuItem>
                </Stack>
            </Menu>
        </>
    );
};
