export {ReactComponent as FullLogoIcon} from './logo-tseh.svg';
export {ReactComponent as SmallLogoIcon} from './logo-small-tseh.svg';
export {ReactComponent as BackIcon} from './back-logo.svg';

export {ReactComponent as CircleIcon} from './circle-icon.svg';
export {ReactComponent as UsersIcon} from './users-icon.svg';
export {ReactComponent as CircleDotIcon} from './circle-dot-icon.svg';
export {ReactComponent as EyeIcon} from './eye.svg';
export {ReactComponent as EyeOffIcon} from './eye-off.svg';

export {ReactComponent as NewsIcon} from './news.svg';
export {ReactComponent as TableIcon} from './table.svg';
export {ReactComponent as SubstitutionIcon} from './table-plus.svg';
export {ReactComponent as BeachIcon} from './beach.svg';
export {ReactComponent as ContactsIcon} from './address-book.svg';
export {ReactComponent as BirthdaysIcon} from './cake.svg';
export {ReactComponent as SitemapIcon} from './sitemap.svg';
export {ReactComponent as WikiIcon} from './brand-wikipedia.svg';
export {ReactComponent as ListDetailsIcon} from './list-details.svg';
export {ReactComponent as CheckboxIcon} from './checkbox.svg';
export {ReactComponent as TestsIcon} from './tests.svg';
export {ReactComponent as ChecklistIcon} from './checklist.svg';
export {ReactComponent as AuditsIcon} from './checkup-list.svg';
export {ReactComponent as CloudIcon} from './cloud-computing.svg';
export {ReactComponent as GiftIcon} from './gift.svg';
export {ReactComponent as UserShieldIcon} from './user-shield.svg';
export {ReactComponent as LogsIcon} from './logs.svg';
export {ReactComponent as HelpIcon} from './lifebuoy.svg';
export {ReactComponent as DocsIcon} from './file-description.svg';
export {ReactComponent as IdeaIcon} from './bulb.svg';

export {ReactComponent as UserIcon} from './user.svg';
export {ReactComponent as SettingsIcon} from './settings.svg';
export {ReactComponent as UsersAddIcon} from './users-plus.svg';
export {ReactComponent as LogoutIcon} from './logout.svg';
export {ReactComponent as NotificationsIcon} from './bell.svg';
export {ReactComponent as SunIcon} from './sun.svg';
export {ReactComponent as MoonIcon} from './moon-stars.svg';
export {ReactComponent as DesktopIcon} from './device-desktop-analytics.svg';
export {ReactComponent as BurgerIcon} from './menu-2.svg';

export {ReactComponent as RightIcon} from './chevron-right.svg';
export {ReactComponent as LeftIcon} from './chevron-left.svg';

export {ReactComponent as ArrowUpIcon} from './arrow-narrow-up.svg';
export {ReactComponent as ArrowDownIcon} from './arrow-narrow-down.svg';

export {ReactComponent as PlusIcon} from './plus.svg';
