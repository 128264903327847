import {Box, Link, Stack, Theme, Typography, useMediaQuery} from '@mui/material';
import profileBackground from 'assets/images/profile-background.png';
import {styled} from '@mui/material/styles';
import emptyAvatar from 'assets/images/empty-avatar.png';
import * as React from 'react';
import {useAuthContext} from 'context/authContext';
import {fullName} from 'utils/functions';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {ModalWidth, openModal} from 'context/modalEffector';
import {Paper} from 'components/accessory/Paper';
import {ProfileInfo} from './components/ProfileInfo';
import {ScheduleWork} from './components/ScheduleWork';
import {Colleagues} from './components/Colleagues';
import {MyDocuments} from './components/MyDocuments';
import {MyDivisions} from './components/MyDivisions';
import {useDeleteApiBandaUploadAvatarRemove, usePostApiBandaUploadAvatar} from 'api/файлы';
import {openToast, TOAST_TYPE} from 'context/toastEffector';
import {uploadAvatarMessage} from 'utils/constants/messages';
import {UploadAvatarModal} from 'pages/Users/pages/Accounts/modals/UploadAvatar';

export const Profile = () => {
    return (
        <Stack gap={{xs: 1, md: 3}} mb={{xs: 1, md: 3}}>
            <Paper>
                <ProfileBackground />
                <MainUserPanel />
            </Paper>
            <Stack direction={'row'} gap={{xs: 1, md: 3}} flexWrap={'wrap'}>
                <Stack gap={{xs: 1, md: 3}} sx={{width: {xs: '100%', md: 360}}}>
                    <ProfileInfo />
                    <Colleagues />
                    <MyDivisions />
                </Stack>
                <Stack gap={{xs: 1, md: 3}} flexGrow={1}>
                    <ScheduleWork />
                    <MyDocuments />
                </Stack>
            </Stack>
        </Stack>
    );
};

const MainUserPanel = () => {
    const {me} = useAuthContext();
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    return (
        <Stack height={{xs: 'auto', md: 124}} p={2} direction={isMd ? 'row' : 'column'} gap={3} alignItems={'center'}>
            <Stack sx={{mt: -6}}>
                <UploadAvatar />
            </Stack>
            <Stack gap={3} alignItems={isMd ? 'flex-start' : 'center'}>
                <Typography variant={'h4'} textAlign={{xs: 'center', md: 'initial'}}>
                    {fullName(me)}
                </Typography>
                <Stack direction={'row'} gap={3}>
                    {MAIN_INFO.map((el, id) => (
                        <Stack direction={'row'} key={id} gap={1} alignItems={'center'}>
                            {el.icon}
                            <Typography>{el.title(me)}</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
        </Stack>
    );
};

const MAIN_INFO = [
    {
        title: (me: any) => (
            <Link href={`mailto:test@example.com`} color={'textPrimary'} underline={'none'}>
                {me?.email}
            </Link>
        ),
        icon: <EmailOutlinedIcon />
    }
];

const UploadAvatar = () => {
    const uploadAvatarMutation = usePostApiBandaUploadAvatar();
    const deleteAvatarMutation = useDeleteApiBandaUploadAvatarRemove();
    const {refetchProfile, me} = useAuthContext();
    const setPhoto = (photo: any) => {
        const formData = new FormData();
        const thenPromise = (text: string) => {
            openToast({
                text,
                type: TOAST_TYPE.SUCCESS
            });
            refetchProfile();
        };
        const catchPromise = (error: any) => {
            openToast({
                text: error?.message,
                type: TOAST_TYPE.ERROR
            });
        };
        if (photo) {
            formData.append('file', photo);
            uploadAvatarMutation
                .mutateAsync({
                    data: {file: photo}
                })
                .then(() => {
                    thenPromise(uploadAvatarMessage);
                })
                .catch((error) => {
                    catchPromise(error);
                });
        } else {
            deleteAvatarMutation
                .mutateAsync()
                .then(() => {
                    thenPromise(uploadAvatarMessage);
                })
                .catch((error) => {
                    catchPromise(error);
                });
        }
    };
    return (
        <>
            <Stack
                onClick={() =>
                    openModal({
                        title: 'Редактирование фото',
                        body: <UploadAvatarModal {...{setPhoto, me}} />,
                        sx: ModalWidth(422)
                    })
                }
                sx={{
                    p: 1,
                    borderRadius: '10px',
                    backgroundColor: (theme) => theme.palette.background.paper,
                    cursor: 'pointer',
                    width: 'fit-content'
                }}>
                <Box
                    width={120}
                    height={120}
                    src={me?.avatar?.url || emptyAvatar}
                    component={'img'}
                    sx={{
                        borderRadius: '6px',
                        objectFit: 'contain',
                        maxWidth: 120,
                        border: (theme) => '1px solid ' + theme.palette.divider
                    }}
                />
            </Stack>
        </>
    );
};
const ProfileBackground = styled(Stack)(({theme}) => ({
    borderRadius: '6px 6px 0 0',
    marginBottom: 0,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${profileBackground})`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
        height: 150
    },
    [theme.breakpoints.up('md')]: {
        height: 200
    }
}));
