import {CssBaseline, ThemeProvider} from '@mui/material';
import {useRoutes} from 'react-router-dom';
import {routes} from './routes';
import createAppTheme from './theme';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {AuthProvider} from 'context/authContext';
import {ModalEffector} from 'context/toastEffector';
import {useUnit} from 'effector-react';
import {$theme} from 'context/themeEffector';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '@fontsource/inter-tight/300.css';
import '@fontsource/inter-tight/400.css';
import '@fontsource/inter-tight/500.css';
import '@fontsource/inter-tight/700.css';

const queryClient = new QueryClient();
const App = () => {
    const content = useRoutes(routes);
    return (
        <ThemeProvider theme={createAppTheme(useUnit($theme))}>
            <QueryClientProvider client={queryClient}>
                <ModalEffector>
                    <AuthProvider>
                        <CssBaseline />
                        {content}
                    </AuthProvider>
                </ModalEffector>
            </QueryClientProvider>
        </ThemeProvider>
    );
};

export default App;
