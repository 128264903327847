import {IMask} from 'react-imask';

export const drawerWidth = 260;

export enum ROUTES {
    BASE = '/',

    AUTH = '/login',
    SSO_AUTH = '/login/sso',
    PROFILE = '/profile',
    PROFILE_SETTINGS = '/profile-settings',

    NEWS = '/news',
    TABLE = '/table',
    SUBSTITUTIONS = '/substitutions',
    VACATIONS = '/vacations',
    CONTACTS = '/contacts',
    BIRTHDAYS = '/birthdays',
    STRUCTURE = 'structure',
    WIKI = '/wiki',
    REQUESTS = '/requests',
    SURVEYS = '/surveys',
    TESTS = '/tests',
    CHECK_LISTS = '/check-lists',
    AUDITS = '/audits',
    STORAGE = '/storage',
    THANK = '/thank',
    USER = '/user',
    USER_ACCOUNTS = '/user/accounts',
    USER_GROUPS = '/user/groups',
    USER_SERVICE_ACCOUNTS = '/user/service-accounts',
    USER_PROFILES = '/user/profiles',
    USER_SCHEDULERS = '/user/schedulers',
    USER_POSITIONS = '/user/positions',
    USER_DIVISIONS = '/user/divisions',
    PERMISSIONS = '/permissions',
    PERMISSIONS_LIST = '/permissions/list',
    PERMISSIONS_GROUPS = '/permissions/groups',
    PERMISSIONS_ROLES = '/permissions/roles',
    LOGS = '/logs',
    HELP = '/help',
    DOCUMENTATION = '/documentation',
    IDEA = '/idea'
}

export enum ERROR_MESSAGE {
    REQUIRED = 'Обязательное поле',
    REQUIRED_EMPTY = ' ',
    EMAIL = 'Неверный формат e-mail',
    INVALID_CREDENTIALS = 'Неверная почта или пароль',
    USER_BLOCKED = 'Пользователь заблокирован. Обратитесь к администратору системы'
}

export const MASKS = {
    NAME: /[ЁёА-яa-zA-Z\s-]$/,
    PHONE: '+{7} (000) 000-00-00',
    EMAIL: /^\S*@?\S*$/,
    TIME: {
        mask: 'hh:mm',
        lazy: false,
        autofix: true,
        blocks: {
            hh: {mask: IMask.MaskedRange, placeholderChar: '0', from: 0, to: 23, maxLength: 1},
            mm: {mask: IMask.MaskedRange, placeholderChar: '0', from: 0, to: 59, maxLength: 1}
        }
    }
};
