/* eslint-disable camelcase */
import * as React from 'react';
import {useGetApiBandaSystemAccessesGroupsShow} from 'api/система';
import {TableLayout} from 'components/layouts/MainLayout/components/TableLayout';
import {useQsParams} from 'utils/hooks/useQsParams';

export default function PermissionGroupsTable({form}: {form: any}) {
    const [params] = useQsParams();
    const {data: groups, isLoading} = useGetApiBandaSystemAccessesGroupsShow(
        {},
        {
            page: params.page || 1,
            per_page: params.per_page || '25'
        }
    );
    return (
        <TableLayout
            columns={PERMISSION_STRUCTURE}
            rows={groups?.data || []}
            isLoading={isLoading}
            pagination={groups?.pagination}
            negativeMargin
        />
    );
}

const PERMISSION_STRUCTURE = [
    {
        title: 'Название',
        value: (item: any) => item.name
    }
];
