import {RouteObject} from 'react-router';
import MainLayout from 'components/layouts/MainLayout';
import {Authorization, Home, News, Profile, Scheduler, Users} from 'pages';
import {ROUTES} from 'utils/constants';

export const routes: RouteObject[] = [
    {
        element: <MainLayout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: ROUTES.AUTH,
                element: <Authorization />
            },
            {
                path: ROUTES.SSO_AUTH,
                element: <Authorization />
            },
            {
                path: ROUTES.PROFILE,
                element: <Profile />
            },
            {
                path: ROUTES.NEWS,
                element: <News />
            },
            {
                path: ROUTES.REQUESTS,
                element: <div />
            },
            {
                path: ROUTES.KNOWLEDGE_BASE,
                element: <div />
            },
            {
                path: ROUTES.STORAGE,
                element: <div />
            },
            {
                path: ROUTES.AUDITS,
                element: <div />
            },
            {
                path: ROUTES.TEST,
                element: <div />
            },
            {
                path: ROUTES.TEST,
                children: [
                    {index: true, element: <div />},
                    {
                        path: ROUTES.TESTEST,
                        element: <div />
                    }
                ]
            },
            {
                path: ROUTES.USERS,
                element: <Users />
            },
            {
                path: ROUTES.SCHEDULER,
                element: <Scheduler />
            }
        ]
    }
];
