import {Box, IconButton} from '@mui/material';
import {NotificationsIcon} from 'assets/icons';
import * as React from 'react';

export const Notifications = () => {
    return (
        <IconButton sx={{color: (theme) => theme.palette.text.heading, position: 'relative'}}>
            <NotificationsIcon style={{width: 22, height: 22}} />
            <Box
                sx={{
                    position: 'absolute',
                    width: 8,
                    height: 8,
                    background: (theme) => theme.palette.secondary.main,
                    borderRadius: '100%',
                    top: 8,
                    right: 10,
                    border: '1px solid #e6e6e8'
                }}
            />
        </IconButton>
    );
};
