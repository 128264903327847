/* eslint-disable camelcase */
import * as React from 'react';
import {useGetApiBandaSystemAccessesRoles} from 'api/система';
import {TableLayout} from 'components/layouts/MainLayout/components/TableLayout';
import {useQsParams} from 'utils/hooks/useQsParams';

export default function PermissionRolesTable({form}: {form: any}) {
    const [params] = useQsParams();
    const {data: roles, isLoading} = useGetApiBandaSystemAccessesRoles(
        {},
        {
            page: params.page || 1,
            per_page: params.per_page || '25'
        }
    );
    return (
        <TableLayout
            columns={PERMISSION_STRUCTURE}
            rows={roles?.data || []}
            isLoading={isLoading}
            pagination={roles?.pagination}
            negativeMargin
        />
    );
}

const PERMISSION_STRUCTURE = [
    {
        title: 'Название',
        value: (item: any) => item.name
    }
];
