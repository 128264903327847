import cookies from 'js-cookie';
import {removeTokenAsArray} from 'utils/functions/index';

export const getTokens = () => ({
    accessToken: cookies.get('accessToken')
    // refreshToken: cookies.get('refreshToken')
});

export const setTokens = (response: any) => {
    const domainOption = {domain: '.' + location.host};
    cookies.set('accessToken', response?.access_token, {
        expires: 365,
        ...(location.hostname === 'localhost' ? {} : domainOption)
    });
    // cookies.set('refreshToken', response?.refresh_token);
};
export const removeTokens = () => {
    const thisToken = cookies.get('accessToken');
    removeTokenAsArray(thisToken as string);
    cookies.remove('accessToken', location.hostname === 'localhost' ? undefined : {domain: '.' + location.host});
    // cookies.remove('refreshToken');
};
