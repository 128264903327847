import {ROUTES} from 'utils/constants/index';
import {
    AuditsIcon,
    KnowledgeIcon,
    NewsIcon,
    ProfileIcon,
    RequestIcon,
    SchedulerIcon,
    StorageIcon,
    UsersIcon
} from 'assets/icons';

export const MENU_ITEMS = [
    {
        title: 'Меню',
        links: [
            {
                title: 'Профиль',
                href: ROUTES.PROFILE,
                icon: <ProfileIcon />
            },
            {
                title: 'График работы',
                href: ROUTES.SCHEDULER,
                icon: <SchedulerIcon />
            },
            {
                title: 'Новости',
                href: ROUTES.NEWS,
                icon: <NewsIcon />
            },
            {
                title: 'Заявки',
                href: ROUTES.REQUESTS,
                icon: <RequestIcon />
            },
            {
                title: 'База знаний',
                href: ROUTES.KNOWLEDGE_BASE,
                icon: <KnowledgeIcon />
            },
            {
                title: 'Хранилище',
                href: ROUTES.STORAGE,
                icon: <StorageIcon />
            },
            {
                title: 'Аудиты',
                href: ROUTES.AUDITS,
                icon: <AuditsIcon />
            },
            {
                title: 'test2',
                children: [
                    {
                        title: 'test9',
                        href: ROUTES.TESTEST
                    }
                ]
            }
        ]
    },
    {
        title: 'Системные настройки',
        links: [
            {
                title: 'Пользователи',
                href: ROUTES.USERS,
                icon: <UsersIcon />
            }
        ]
    }
];
export const FOOTER_LINKS = [
    {title: 'Цех85', href: '#'},
    {title: 'Контакты', href: '#'},
    {
        title: 'База знаний',
        href: '#'
    },
    {title: 'Поддержка', href: '#'}
];
