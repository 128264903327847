import {setTokens} from 'utils/functions/auth';

export const cleanData = (data: {[s: string]: unknown} | ArrayLike<unknown>) =>
    Object.entries(data).reduce((a, [k, v]) => (v == null || v === '' ? a : ((a[k] = v), a)), {} as {[k: string]: any});

export const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';
    for (i = 0; i < 3; i += 1) {
        let value = (hash >> (i * 8)) & 0xff;
        value += Math.floor(Math.random() * 50);
        value = Math.min(value, 255);
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
};
export const stringAvatar = (name: string) => {
    return {
        sx: {
            bgcolor: stringToColor(name)
        },
        children: `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}`
    };
};

export const capitalizeFLetter = (string: string) => {
    if (!string) return string;
    return string[0].toUpperCase() + string.slice(1);
};

export const fullName = (me: any) => {
    return (
        capitalizeFLetter(me?.first_name) +
        ' ' +
        capitalizeFLetter(me?.last_name) +
        ' ' +
        capitalizeFLetter(me?.patronymic)
    );
};
export const addNewToken = (token: string) => {
    const tokens = JSON.parse(localStorage.getItem('tokens') as string) || [];
    if (!tokens.includes(token)) {
        tokens.push(token);
    }
    // eslint-disable-next-line camelcase
    setTokens({access_token: token});
    localStorage.setItem('tokens', JSON.stringify(tokens.filter((e: any) => e)));
};
export const removeTokenAsArray = (token: string) => {
    const tokens = JSON.parse(localStorage.getItem('tokens') as string) || [];
    const tokenIndex = tokens.indexOf(token);
    if (tokenIndex !== -1) {
        tokens.splice(tokenIndex, 1);
    }
    localStorage.setItem('tokens', JSON.stringify(tokens.filter((e: any) => e)));
};
export const getTokensArray = () => {
    const tokens = JSON.parse(localStorage.getItem('tokens') as string) || [];
    return tokens;
};

export const removeDuplicatesById = (array: any[]) => {
    const uniqueArray = [];
    const seenIds = new Set();
    for (const item of array) {
        if (!seenIds.has(String(item.id))) {
            uniqueArray.push(item);
            seenIds.add(String(item.id));
        }
    }
    return uniqueArray;
};
// export const resultTheme = () => {
//     const theme = useUnit($theme);
//     if (theme === MODE.SYSTEM) {
//         if (window.matchMedia('(prefers-color-scheme: DARK)').matches) {
//             return MODE.DARK;
//         } else return MODE.LIGHT;
//     } else return theme;
// };
